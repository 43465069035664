<template>

  
    <!-- <div class="content"> -->
      <v-row>


        <v-col> 
          <div class="container-register">
            <div v-show="!showResult"> 
              <div v-show="!showLoading">
                <div class="title">
                  Do you want to login faster?
                </div>
                <img class="icon-feedback" src="../assets/icon_faceID.png">
                <p class="description">Please enable your Fingerprint or Face ID of this device to your profile</p>
                <button  v-on:click="registerAuthenticator" class="button-register">Register Biometrics</button>
              </div>

              <div v-show="showLoading">
                <div>
                  <img class="icon-feedback" src="../assets/loading.gif">
                  <p> {{ resultMessage }}</p>
                </div>
              </div> 
            </div>
            
            <div v-show="showResult">
              <div v-show="showRegistrationSuccess">
                <img class="icon-feedback" src="../assets/icon_success.png">
                <p> {{ resultMessage }}</p>
              </div>
              <div v-show="!showRegistrationSuccess">
                <img class="icon-feedback" src="../assets/icon_failed.png">
                <p>{{ resultMessage }}</p>
              </div>
            </div> 
          </div>


            
        </v-col>
      </v-row>
      
    
  <!-- </div>  -->


</template>

<script>

  // import axios from "axios";
  const SimpleWebAuthnBrowser = require('@simplewebauthn/browser');
  import { getUA } from 'mobile-device-detect';
  import { emitter } from "../event-bus.js";

  


  export default {
    name: "RegisterBiometrics",
    data() {
      return {
        result: false,
        registrationSuccess: false,
        title: "Register",
        resultMessage: "",
        loading: false,
        scaUrl: process.env.VUE_APP_SCA_URL,
        email: '',
        mobileModel: ''
      }
    },
    created () {
      this.mobileModel = getUA;
    },
    mounted () {
      emitter.on('email_filled', e => this.email = e );
      // emitter.on('email_filled', e => console.log(e));
      // console.log(this.email);
    },
    computed: {
      showLoading() {
        return this.loading;
      },
      showResult() {
        return this.result;
      },
      showRegistrationSuccess() {
        return this.registrationSuccess
      }
    },
    methods: {
      registerAuthenticator: async function() {
        const { startRegistration } = SimpleWebAuthnBrowser;

        this.initial = false;
          this.loading = true;
          this.resultMessage = "Please register your biometrics and wait"
        // console.log(this.email);
        const resp = await fetch(this.scaUrl + '/generate-registration-options');


          let attResp;
          try {
            const opts = await resp.json();
            console.log(opts);
            attResp = await startRegistration(opts);
            console.log(attResp);
          } catch (error) {
            if (error.name === 'InvalidStateError') {
              
              this.result=true;
              this.registrationSuccess = false;
              this.loading = false;
              this.title = "Registration Failed";
              this.resultMessage = "Your biometrics are already registered before."
            } else {
              this.loading = false;
              this.resultMessage = "We couldn't add your biometrics due to unknown reason."
            }
            this.loading = false;
            throw error;
          }


          const verificationResp = await fetch(this.scaUrl + '/verify-registration', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(attResp),
          });



          const verificationJSON = await verificationResp.json();
          this.loading = false;
          this.result = true;
          console.log(verificationJSON);

          if (verificationJSON && verificationJSON.verified) {
            this.registrationSuccess = true;
            this.title = "Registration success"
            this.resultMessage = "Biometrics registration success! Now you can login even easily!"
          } else {
            this.registrationSuccess = false;
            this.title = "Registration failed"
            this.resultMessage = "Oh no, something went wrong!";
          }
      },
      
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .title {
    /* Add shadows to create the "card" effect */
    /* background-color:#cc0066; */
    /* width: 343px; */
    height: 36px;

    /* H4/Small */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Neutrals/Black */

    color: #080808;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    /* margin-bottom: -30px; */
  }
  .description {
    /* margin-bottom: 40px; */
    font-size: 14px;
  }
  .container-register {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 24px 40px;
    gap: 40px;
/* 
    width: 375px;
    height: 536px;
    background: #F8FBFC;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; */
    align-items: center;
    /* text-align: center; */
    /* width: 343px; */
    /* height: 536px; */

    background: #F8FBFC;
    /* padding-top: 20px; */
    /* width: 343px; */
    gap: 40px;
  }
  .icon-feedback {
    width: 100%;
    max-width: 180px;
    padding-top: 20px;
    margin-bottom: 20px;
  }
  .button-register {
    /* display: flex;
    flex-direction: row;
    justify-content: center; */
    align-items: center;
    padding: 8px 24px;
    gap: 10px;
    font-weight: 700;
    width: 343px;
    height: 40px;

    /* Primary / Base */
    color: #ffffff;
    background: #CC0066;
    border-radius: 100px;
    border: 0px;

    /* Inside auto layout */


    margin-top: 20px;
  }
  hr.solid {
    border-top: 1px solid #eaf1f4;
  }
  @media screen and (min-width: 320px) {
    .button-preferred-bank {
      font-size: 0.9em;
    }
    .content {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media screen and (min-width: 480px) {
    .button-preferred-bank {
      font-size: 1em;
    }
  }
  

</style>
