import { createRouter, createWebHashHistory } from 'vue-router'
import ThirdPartyLoginView from '../views/ThirdPartyLoginView.vue'
import RegistrationView from '../views/RegistrationView.vue'
import LoginView from '../views/LoginView.vue'
import ProfileView from '../views/ProfileView.vue'
import ProfileDevicesView from '../views/ProfileDevicesView.vue'
import PaymentView from '../views/PaymentView.vue'
import PaymentCheckoutView from '../views/PaymentCheckoutView.vue'
import PaymentNormalCheckoutView from '../views/PaymentNormalCheckoutView.vue'
import PaymentConfirmView from '../views/PaymentConfirmView.vue'
import WalletListView from '../views/WalletListView.vue'
import WalletRegisterView from '../views/WalletRegisterView.vue'


const routes = [
  {
    path: '/thirdpartylogin',
    name: 'thirdpartylogin',
    component: ThirdPartyLoginView
  },
  {
    path: '/registration/:page',
    name: 'registration',
    component: RegistrationView,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView
  },
  {
    path: '/profile/devices',
    name: 'profiledevices',
    component: ProfileDevicesView
  },
  {
    path: '/payment/:transactionId',
    name: 'payment',
    component: PaymentView
  },
  {
    path: '/checkoutpayment/:transactionId',
    name: 'checkoutpayment',
    component: PaymentCheckoutView
  },
  {
    path: '/checkout/:transactionId',
    name: 'checkoutnormalpayment',
    component: PaymentNormalCheckoutView
  },
  {
    path: '/paymentconfirm/:transactionId',
    name: 'paymentconfirm',
    component: PaymentConfirmView
  },
  {
    path: '/walletlist',
    name: 'walletlist',
    component: WalletListView
  },
  {
    path: '/registerwallet',
    name: 'registerwallet',
    component: WalletRegisterView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 };
  }
})

export default router
