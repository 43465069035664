<template>
    <div class="home">
      <WalletListComponent/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import WalletListComponent from '@/components/WalletListComponent.vue'
  
  export default {
    name: 'WalletListView',
    components: {
      WalletListComponent
    }
  }
  </script>
  