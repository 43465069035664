<template>
    <H2>Wallet successfully registered!</H2>
</template>

<script>
export default {
data() {
    return {
    relatedApps: [],
    relatedAppsLabels: [
        { 
            id: 'com.siuwy.pulsar',
            name: 'ABC Bank',
            applink: 'pulsar://' // for demo purposes, just using url scheme in stead of app link
        },
        {
            id: 'com.siuwy.skylark',
            name: '123 Bank',
            applink: 'skylark://' // for demo purposes, just using url scheme in stead of app link
        },
        {
            id: 'com.abnamro.nl.mobile.payments',
            name: 'ABN AMRO',
            applink: 'https://www.abnamro.nl/nl/widgetdelivery/unauthenticated/ideal/dep/nl/index.html?randomizedstring=1452335634&trxid=8153798630636956' // app link of ABN AMRO app (random iDEAL trx for demo purposes)
        },
        {
            id: '',
            name: 'N26',
            applink: 'https://app.n26.com/link/ideal'
        },
        {
            id: '',
            name: 'Revolut',
            applink: 'https://ideal.revolut.com/v2/ideal/transaction?payloadUri=https%3A%2F%2Ftx.ideal.nl%2F2%2FAYZRZ4TEVLPVPYTLKMOWGQFRLYY&sig=BGBDAEIIARWUUDWGXODC2A2H6RNVI4GWAW5FRRFWFRJPZ2RJUPBM74D7VXCZAEIIASFONNJSPQA3X6KXVF56KKRV447LQPX5GMX4IW6M7TDFJL5ZRBOCQ' // app link of Revolut app (random iDEAL trx for demo purposes)
        }
    ],
    walletUsersServiceUrl: process.env.VUE_APP_WALLET_USERS_SERVICE_URL,
    };
},
created() {
    this.RegisterWallet();
    // this.getExistingWallets();
},
methods: {
    async RegisterWallet() {

    const walletApp = this.relatedAppsLabels.filter(wallet => wallet.name === this.$route.query.walletName);

    delete walletApp[0].icon;

    const selectedWallet = walletApp[0];
    const suppressLanding = this.$route.query.suppressLanding === "true" ? true : false;


    // Add wallet to cookie
    const request = {
        selectedWallet: selectedWallet,
        suppressLanding: suppressLanding
    } 

    const storeWalletResp = await fetch(this.walletUsersServiceUrl + '/users-wallets', {
        method: 'POST',
        headers: {
        'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(request),

    });
    const storedWalletResp = await storeWalletResp.json();   

    console.log(storedWalletResp);

    },
}
};
</script>

<style scoped>
h3 {
    margin: 40px 0 0;
}


.content-container {
    max-width: 400px;
    padding: 20px;
    margin: 0 auto;
}


.left-align {
    text-align: left;
}
.right-align {
    text-align: right;
}


.wallet-list {
    max-height: calc(100vh - 80px); 
    overflow-y: auto;
}

.wallet-row {
    height: 60px;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.remove-wallet-button {
    margin-left: 0px;
    /* flex-grow: 1; */
}

.wallet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.wallet-text {
    /* flex-grow: 1; */
    line-height: 40px;
    padding-left: 30px;
    width: 80%;
}
/* .custom-checkbox-text {
    font-size: 0.75em;
} */
.custom-checkbox {
    font-size: 0.8em;
    margin-left: -10px;
}
/* .custom-checkbox {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
    transform: translateY(-0.075em);
} */
</style>
