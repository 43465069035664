import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import VueSSE from 'vue-sse';
import VueMobileDetection from 'vue-mobile-detection';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
// import AWS from 'aws-sdk';

import { AwsRum } from 'aws-rum-web';

// import awsconfig from './aws-exports';

// import mitt from 'mitt';
// const emitter = mitt();

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// try {

//playground
  const config = {
    sessionSampleRate: 1,
    guestRoleArn: "arn:aws:iam::471392165117:role/cognito-unauth-poc-pages",
    identityPoolId: "eu-central-1:46eca698-ccd2-4793-afc1-5ec447458572",
    endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
    telemetries: ["http","errors","performance"],
    allowCookies: true,
    enableXRay: false
  };

  const APPLICATION_ID = 'ac13f413-a4a9-4dd3-947c-0e054d3cfec7';
  const APPLICATION_VERSION = '1.0.0';
  const APPLICATION_REGION = 'eu-central-1';

  const awsRum = new AwsRum(
    APPLICATION_ID,
    APPLICATION_VERSION,
    APPLICATION_REGION,
    config
  );

//   const evidentlyConfig = {
//     region: 'eu-central-1', // Your AWS region
//     identityPoolId: 'eu-central-1:d2ccdfbd-296f-40e0-bb39-2e727ca5284b', // Your Cognito Identity Pool ID
// };

//   // Update AWS config
//   AWS.config.update({
//       region: evidentlyConfig.region,
//       credentials: new AWS.CognitoIdentityCredentials({
//           IdentityPoolId: evidentlyConfig.identityPoolId
//       })
//   });

  // const config = {
  //   sessionSampleRate: 1,
  //   guestRoleArn: "arn:aws:iam::471392165117:role/RUM-Monitor-eu-central-1-471392165117-8215635485761-Unauth",
  //   identityPoolId: "eu-central-1:5a02ca7d-d62d-4300-be8b-9aa03a55e67e",
  //   endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
  //   telemetries: ["performance"],
  //   allowCookies: true,
  //   enableXRay: false
  // };

  // const APPLICATION_ID = '34d028b1-5624-4daa-8866-00053fafbcfa';
  // const APPLICATION_VERSION = '1.0.0';
  // const APPLICATION_REGION = 'eu-central-1';

  // const awsRum = new AwsRum(
  //   APPLICATION_ID,
  //   APPLICATION_VERSION,
  //   APPLICATION_REGION,
  //   config
  // );

  // const config = {
  //   sessionSampleRate: 1,
  //   guestRoleArn: "arn:aws:iam::531665809881:role/cognito-unauth-profile-main-eu-central-1",
  //   identityPoolId: "eu-central-1:f62b3373-3d72-48fa-9256-5046a959b106",
  //   endpoint: "https://dataplane.rum.eu-central-1.amazonaws.com",
  //   telemetries: ["performance","errors","http"],
  //   allowCookies: true,
  //   enableXRay: false
  // };

  // const APPLICATION_ID = 'a2ab8c27-0c39-4773-abd4-e6a96d29d64f';
  // const APPLICATION_VERSION = '1.0.0';
  // const APPLICATION_REGION = 'eu-central-1';

  // const awsRum = new AwsRum(
  //   APPLICATION_ID,
  //   APPLICATION_VERSION,
  //   APPLICATION_REGION,
  //   config
  // );



// } catch (error) {
//   // Ignore errors thrown during CloudWatch RUM web client initialization
// }





const vuetify = createVuetify({
    components,
    directives,
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        fa,
      },
    },
  })



let app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(fas) // Include needed solid icons
library.add(far) // Include needed regular icons

app.config.globalProperties = { awsRum };
app
  .use(router)
  .use(VueMobileDetection)
  .use(Datepicker)
  .use(vuetify)
app.mount('#app')

// const pociDEALApp = createApp(App);
// pociDEALApp
//     .use(router)
//     // .use(VueSSE)
//     .use(VueMobileDetection)
//     .use(Datepicker)
//     .use(vuetify)
//     // .use(emitter);
// pociDEALApp.mount('#app')
// // createApp(App).use(router).mount('#app')

// // eslint-disable-next-line no-unused-vars
// pociDEALApp.config.warnHandler = function (msg, vm, trace) { 
    

//     return null
//   }
