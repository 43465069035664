<template>
    <div class="home">
      <WalletRegisterComponent/>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import WalletRegisterComponent from '@/components/WalletRegisterComponent.vue'
  
  export default {
    name: 'WalletRegisterView',
    components: {
        WalletRegisterComponent
    }
  }
  </script>
  