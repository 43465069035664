<template>
  <div class="main">
    
      <div class="top-bar">
        <div class="content-wrapper">
          <img class="ideal-logo" src="../assets/ideal-logo.png">
        </div>
        
      </div>
      <div class="menu-bar">
        <div class="content-wrapper">
          <a :href="merchantUrl + '/#/login'">
            <div v-show="!qrscanned" class="cancel-button">
              Cancel
            </div>
          </a>
        </div>
      </div>
      <div class="qrcode-content" v-show="!qrscanned">
        <p class="title">Scan with iDEAL Login</p>
        <img class="qrcode" src="../assets/qrcode.png">
      </div>
      <div v-show="qrscanned" class="qrcode-content">
        <div>
          <p class="title">Please finish login on your mobile device</p>
          <img class="qrcode" src="../assets/loading.gif">
        </div>
      </div> 
      <div class="download-app">
        <p class="description">Do you want to login easily?</p>
        <img class="stores" src="../assets/stores.png">
      </div>
      <div class="bottom-bar">
       
          <table class="bottom-menu">
            <tr>
              <td>How does it work?</td>
              <td>About us</td>
              <td>Privacy statement</td>
              <td><b>Nederlands</b></td>
            </tr>
            <tr>
              <td>© 2022 IDEAL</td>
            </tr>
          </table>
          
      </div>

    
  </div>
  
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'HomeView',
  // components: {
  //   HelloWorld
  // }
  data () {
    return {
      polling: null,
      qrscanned: false,
      merchantUrl: process.env.VUE_APP_MERCHANT_URL
    }
  },
  mounted () {
    this.resetLogin();
    this.pollLoginStatus();

    // this.setupStream();
    document.title = "iDEAL Login";  
  },
  beforeUnmount () {
	clearInterval(this.polling);
  },
  methods: {
    resetLogin: async function () {

      // let loginStatus = { 
      //     loginId: "walletid0901",
      //     status: "OPEN"
      // }

      // console.log(process.env.VUE_APP_API_URL);
    
      // const resp = await fetch(process.env.VUE_APP_API_URL + '/logins', {
      //     method: 'POST',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //     body: JSON.stringify(loginStatus),
      // });

      const resp = await fetch(process.env.VUE_APP_API_URL + '/loginreset');

      const loginResp = await resp.json();
      if (loginResp.status == 'OPEN') {
        this.qrscanned = false;
      } 
    },
    pollLoginStatus: async function() {
      this.polling = setInterval(async () => {
        const resp = await fetch(process.env.VUE_APP_API_URL + '/logins/' + "walletid0901");

        const loginResponse = await resp.json();

        if (loginResponse.status == 'OPEN') {
          this.qrscanned = false;
        }


        if (loginResponse.status == 'SCANNED') {
          this.qrscanned = true;
        }

        if (loginResponse.status == 'SUCCESS') {
          window.location.href = process.env.VUE_APP_MERCHANT_URL + "/#/myohra"
        }
      }, 2000)
    }
      // setupStream () {
      //   let evtSource = new EventSource(process.env.VUE_APP_API_URL + "/events")
                  
      //   evtSource.addEventListener('message', event => {
      //     // let data = JSON.parse(event.data)
      //     let data = event.data;

      //     // this.now = data.msg
      //     // this.data = data
      //     if (data == 'LOGIN_SUCCESS') {
      //       window.location.href = "http://localhost:8080/merchant?session=true";
      //     }

      //     if (data == 'QR_SCANNED') {
      //       this.loading = true;
      //       this.resultMessage = 'Please continue login on mobile device';
      //     }
          
      //   }, false)

      //   evtSource.addEventListener('error', event => {
      //       if (event.readyState == EventSource.CLOSED) {
      //           // console.log('Event was closed');
      //           // console.log(EventSource);
      //       }
      //   }, false);          
      // }
  }
}
</script>

<style scoped>

  .main {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .content-wrapper {
    padding: 15px;
    width: 100%;
    max-width: 52em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  a {
    text-decoration:none;
  }

  .top-bar {
    /* justify-content: center;
    align-items: center; */
    position: relative;
    height: 80px;
    background: #C9DDE4;
    box-shadow: 0px 2px 6px rgba(109, 164, 182, 0.15);
  }
  .top-bar .ideal-logo {
    width: 50px;
  }
  .menu-bar {
    height: 15px;

  }
  .menu-bar .cancel-button {
    font-family: 'Montserrat';
    color: #CC0066;
    font-weight: 700;
    /* margin-top: -5px; */
  }
  .qrcode-content {
    position: relative;
    background: #F8FBFC;
    margin-bottom: 20px;
  }

  .qrcode-content .title {
    padding-top: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #080808;
    
  }

  .qrcode-content .qrcode {
    width: 300px;
    padding-top: 5px;
    padding-bottom: 20px;
  }

  .download-app {
    /* height: 180px; */
    background: #F8FBFC;
  }

  .download-app .stores {
    margin-top: -60px;
    height: 100px;
  }

  .download-app .description {
    padding: 40px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #080808;
  }

  .bottom-bar {
    /* width: 130px;
    height: 24px; */

    /* Body 2/Large */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* display: flex;
    align-items: center; */

    /* Grey/Base */

    color: #525C62;


    /* Inside auto layout */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
  }

  .bottom-menu {
    padding-top: 40px;
    width: 100%;
    max-width: 60em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }


</style>
