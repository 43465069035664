<template>
  <!-- <div class="main"> -->
    <div class="top-bar">
        <div class="content-wrapper">
          <img class="ideal-logo" src="../assets/ideal-logo.png">
          <div class="payment-details">
            <p >
              <span class="amount">€ {{ amount }}</span>
              <span class="merchant-name">{{ transaction.creditorName }}</span>
            </p>
          </div>
        </div>
        
    </div>
    <div v-show="showCheckout">
      <div class="menu-bar">
        <div class="content-wrapper">
          <!-- <a :href="merchantUrl + '/#/login'"> -->
            <div class="cancel-button">
              Cancel
            </div>
          <!-- </a> -->
        </div>
      </div>
      <div  class="content-wrapper">
        <div class="checkout-title">
          <div class="title">
            Order Summary
          </div>
        </div>
        <v-container>
          <v-row>
            <v-col>
              <div class="content">
          
                <div class="box-details"> 
                  <img v-show="showUnmaskIcon" v-on:click="unmaskData" class="icon-eye" src="../assets/icon_eye.png">
                  <img class="icon-pencil" src="../assets/icon_pencil.png">
                  <div><b>Contact information</b></div><br>
                  <div  v-show="showUnmaskIcon"><span>{{phoneNumberPart1}}</span><span class="blur">{{phoneNumberPart2}}</span><span>{{phoneNumberPart3}}</span><br></div>
                  <div v-show="!showUnmaskIcon">{{phoneNumber}}</div>
                  {{userName}}
                </div>
                <div>
                  <div class="box-details">
                    <img v-show="showUnmaskIcon" v-on:click="unmaskData" class="icon-eye" src="../assets/icon_eye.png">
                    <img class="icon-pencil" src="../assets/icon_pencil.png">
                    <div><b>Delivery address</b></div><br>
                    <div v-show="showUnmaskIcon"> 
                      {{deliveryFirstName}} {{deliveryLastNamePart1}}<span class="blur">{{deliveryLastNamePart2}}</span><br>
                      <span class="blur">{{deliveryStreetname}}</span> {{deliveryHouseNumber}}<br>
                      <span class="blur">{{deliveryPostalcodePart1}}</span><span>{{deliveryPostalcodePart2}}</span> {{deliveryCity}}
                    </div>
                    <div v-show="!showUnmaskIcon"> 
                      {{deliveryFirstName}} {{deliveryLastName}}<br>
                      {{deliveryStreetname}} {{deliveryHouseNumber}}<br>
                      {{deliveryPostalcode}} {{deliveryCity}}
                    </div>
                  </div>
                </div>
                <div class="box-details"> 
                  <div>
                    <img v-show="showUnmaskIcon" v-on:click="unmaskData" class="icon-eye" src="../assets/icon_eye.png">
                  </div>
                  <img class="icon-pencil" src="../assets/icon_pencil.png">
                  <div><b>Invoicing address</b></div><br>
                    Same as delivery address
                </div>
                <div class="transaction-summary"> 
                <div class="heading">Payment Details</div>
                  <table class="selected-iban">
                    <tr>
                    <td class="selected-iban-logo">
                      <img class="icon" :src="getIssuerLogoQuickHack(selectedIban)">
                    </td>
                    <td class="selected-iban-text">
                      <div>{{ selectedIbanPart1 }} {{ selectedIbanPart2 }} <span class="blur">{{ selectedIbanPart3 }}</span> {{ selectedIbanPart4 }} {{ selectedIbanPart5 }}</div>

                    </td>
                    <td  class="selected-iban-pencil">  
                        <img v-on:click="toggleBankSelection" class="icon" src="../assets/icon_pencil.png">

                    </td>
                  </tr>

                  </table>
                  <div class="order-summary">
                    <div class="order-details"> 
                      <table class="order-details-table">
                        <tr class="items"><td>Items</td><td class="amounts">€{{orderAmount}} </td></tr>

                        <tr><td>Delivery costs</td><td class="amounts">€{{ shippingCost}} </td></tr>
                        <tr><td colspan="3"><hr class="divider"></td></tr>
                        <tr class="total"><td>Total</td><td class="amounts">€{{amount}} </td></tr>
                      </table>

                    </div>

                </div>

                <button v-on:click="pay" class="button-payment">Complete payment</button>

                <div class="sharing-description">
                  To complete your order, iDEAL will share your delivery details with the webshop
                </div>

                
              </div>
              </div>
            </v-col>

          </v-row>
        </v-container>
      </div>
    </div>
    <div v-show="!showCheckout">

      <div class="menu-bar">
        <div class="content-wrapper">
          <!-- <a :href="merchantUrl + '/#/login'"> -->
            <div class="cancel-button">
              Back
            </div>
          <!-- </a> -->
        </div>
      </div>
      
        <div class="checkout-title">
          <div class="title">
            Select an account
          </div>
        </div>
        <v-container>

        </v-container>
    </div>
    

    <v-dialog
      v-model="dialog"
      :scrim="true"
      persistent
    >
      <div class="modal-in-progress">

          <v-progress-circular
            indeterminate
            color="blue-lighten-3"
            model-value="20"
            :size="128"
            :width="8"
          ></v-progress-circular>
          <p class="modal-description">Please wait</p>

      </div>
    </v-dialog>
    <!-- <v-overlay v-model="dialog"></v-overlay> -->
    <!-- </div> -->
  <!-- </div> -->
</template>

<script>
  // import axios from "axios";
  const SimpleWebAuthnBrowser = require('@simplewebauthn/browser');
  // import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import { ref } from 'vue';
  // import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import '@splidejs/vue-splide/css';
  import logoIssuerABCBank from "../assets/icon-issuer-abcbank.png";
  import logoIssuerING from "../assets/icon-issuer-ing.png";
  import logoIssuerRabobank from "../assets/icon-issuer-rabobank.png";
  import logoIssuerBunq from "../assets/icon-issuer-bunq.png";


  export default {
    name: "PaymentBankSelectionComponent",
    components: { 
      // Datepicker,
      // Splide,
      // SplideSlide 
    },
    data() {
      return {
        title: "Payment",
        scaUrl: process.env.VUE_APP_SCA_URL,
        bffUrl: process.env.VUE_APP_BFF_URL,
        trxId: this.$route.params.transactionId,
        userName: 'jiri.oen@gmail.com',
        amount: 0,
        orderAmount: 0,
        shippingCost: 0,
        discount: 0,
        merchantName: '',
        // date: null,
        transaction: {},
        deliveryFirstName: '',
        deliveryLastName: '',
        deliveryStreetname: '',
        deliveryHouseNumber: '',
        deliveryPostalcode: '',
        deliveryCity: '',
        deliveryCountry: '',
        invoicingFirstName: '',
        invoicingLastName: '',
        invoicingStreetname: '',
        invoicingHouseNumber: '',
        invoicingPostalcode: '',
        invoicingCity: '',
        invoicingCountry: '',
        iban: '',
        issuer: '',
        phoneNumber: '',
        recurringPeriodLabel: '',
        masked: true,
        scheduling: false,
        transactionType: '',
        recurring: false,
        payLater: false,
        payLaterSelected: false,
        selectedIban: '',
        bankAccounts: [],
        dialog: false,
        ibans: [],
        disableFrequencySelection: false,
        frequencySelection: false,
        awsRumClient: this.awsRum,
        checkout: true
      }
    },
    created() {
      this.getUser(this.masked);
      this.getPaymentDetails();


    },
    setup() {
        const date = ref(new Date());
        
        // In case of a range picker, you'll receive [Date, Date]
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        }

        const splide = ref();

        const splideOptions = { 
          arrows: true, 
          perPage: 1,
          gap: '1.2rem', 
          // padding: '30%',
          height: '190px', 
          fixedWidth: '393px', 
          direction: 'ttb',
          // fixedWidth: 311, 

          // isNavigation: true,
          breakpoints: {
            640: {
              // perPage: 1, 
              // trimSpace: true, 
              // fixedHeight: '72px',
              gap: '1.2rem', 
              // padding: '20%',
              height: '100px',
              fixedWidth: '390px',
              arrows: false 

            },
          }
        }
        
        return {
            date,
            format,
            splide,
            splideOptions
        }
    },
    computed: {
      showCheckout() {
        return this.checkout;
      },
      showRecurring() {
        return this.recurring;
      },
      showPayLater() {
        return this.payLater;
      },

      showUnmaskIcon() {
        return this.masked; 
      },
      showScheduling () {
        return this.scheduling;
      },
    },
    methods: {
      getUser: async function(masked) {
        let request = { 
          email: this.userName,
          masked: masked
        }

        const resp = await fetch(this.bffUrl + '/users/identify', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
          });
        const retrievedUser = await resp.json();
        // console.log(retrievedUser);
        
        this.deliveryAddressId = retrievedUser.addresses[0].addressId;
        this.deliveryFirstName = retrievedUser.addresses[0].firstName;
        this.deliveryLastName = retrievedUser.addresses[0].lastName;
        this.deliveryStreetname = retrievedUser.addresses[0].streetName;
        this.deliveryHouseNumber = retrievedUser.addresses[0].houseNumber;
        this.deliveryPostalcode = retrievedUser.addresses[0].postalCode;
        this.deliveryCity = retrievedUser.addresses[0].city;
        this.deliveryCountry = retrievedUser.addresses[0].country;

        this.invoicingAddressId = retrievedUser.addresses[0].addressId;
        this.invoicingFirstName = retrievedUser.addresses[0].firstName;
        this.invoicingLastName = retrievedUser.addresses[0].lastName;
        this.invoicingStreetname = retrievedUser.addresses[0].streetName;
        this.invoicingHouseNumber = retrievedUser.addresses[0].houseNumber;
        this.invoicingPostalcode = retrievedUser.addresses[0].postalCode;
        this.invoicingCity = retrievedUser.addresses[0].city;
        this.invoicingCountry = retrievedUser.addresses[0].country;

        this.iban = retrievedUser.bankAccounts[0].iban;

        this.bankAccounts = retrievedUser.bankAccounts;
        
        let x = 0;
        for (x=0; x < this.bankAccounts.length; x++) {
          this.bankAccounts[x].ibanPart1 = this.bankAccounts[x].iban.substring(0, 4);
          this.bankAccounts[x].ibanPart2 = this.bankAccounts[x].iban.substring(4, 8);
          this.bankAccounts[x].ibanPart3 = this.bankAccounts[x].iban.substring(8, 14);
          this.bankAccounts[x].ibanPart4 = this.bankAccounts[x].iban.substring(14, 16);
          this.bankAccounts[x].ibanPart5 = this.bankAccounts[x].iban.substring(16, 18);

          if (this.bankAccounts[x].preferred) {
            this.selectedIban = this.bankAccounts[x].iban;
            this.selectedIbanPart1 = this.selectedIban.substring(0, 4);
            this.selectedIbanPart2 = this.selectedIban.substring(4, 8);
            this.selectedIbanPart3 = this.selectedIban.substring(8, 14);
            this.selectedIbanPart4 = this.selectedIban.substring(14, 16);
            this.selectedIbanPart5 = this.selectedIban.substring(16, 18);
          }
        }

        // this.selectedIban = this.bankAccounts[0].iban;


        

        for (let x=0; x < this.bankAccounts.length; x++) {
          this.ibans.push(this.bankAccounts[x].iban)
        }

        this.issuer = retrievedUser.bankAccounts[0].issuer;
        this.phoneNumber = retrievedUser.phoneNumber;
        
        this.checkout = false;
        // this.recurring = false;

        if (masked) {
          this.phoneNumberPart1 = this.phoneNumber.substring(0, 4);
          this.phoneNumberPart2 = this.phoneNumber.substring(4, 8);
          this.phoneNumberPart3 = this.phoneNumber.substring(8, 12);

          this.deliveryLastNamePart1 = this.deliveryLastName.substring(0,1)
          this.deliveryLastNamePart2 = this.deliveryLastName.substring(1,this.deliveryLastName.length-1);
          this.deliveryPostalcodePart1 = this.deliveryPostalcode.substring(0, 4)
          this.deliveryPostalcodePart2 = this.deliveryPostalcode.substring(5, 7)          

          this.invoicingLastNamePart1 = this.invoicingLastName.substring(0,1)
          this.invoicingLastNamePart2 = this.invoicingLastName.substring(1,this.invoicingLastName.length-1);
          this.invoicingPostalcodePart1 = this.invoicingPostalcode.substring(0, 4)
          this.invoicingPostalcodePart2 = this.invoicingPostalcode.substring(5, 7)
          
          this.ibanPart1 = this.iban.substring(0, 8);
          this.ibanPart2 = this.iban.substring(8, 14);
          this.ibanPart3 = this.iban.substring(14, 18);
        }
      },
      toggleBankSelection: function() {
        console.log('toggle clicked')
        this.checkout = false;

      },

            // eslint-disable-next-line no-unused-vars
      onSplideMove: function(newIndex, currentIndex, prevIndex) {
        this.selectedIban = this.bankAccounts[currentIndex].iban
      },
      authenticate: async function() {


          const { startAuthentication } = SimpleWebAuthnBrowser;
          const resp = await fetch(this.scaUrl + '/generate-authentication-options');

          let asseResp;
          try {
            const opts = await resp.json();
            console.log('authenticate', opts);
            
            asseResp = await startAuthentication(opts);

          } catch (error) {
            // elemError.innerText = error;
            throw new Error(error);
          }

          

          // console.log(asseResp);
          
          const authResponse = await fetch(this.scaUrl + '/verify-authentication', {
            method: 'POST',
            credentials: 'include',
            // mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(asseResp),
          });

          const authResponseJSON = await authResponse.json();
          console.log(authResponseJSON);
          

          if (authResponseJSON && authResponseJSON.verified) {
            this.$loggedIn = true;
            return true;
            
          } else {
            alert("Authentication Failed!");
          }
      },
      authorisePayment: async function(signItemId) {


          const { startAuthentication } = SimpleWebAuthnBrowser;
          const resp = await fetch(this.scaUrl + '/sign/generate-signing-options/' + signItemId);

          let asseResp;
          try {
            const opts = await resp.json();            
            asseResp = await startAuthentication(opts, false);

          } catch (error) {
            // elemError.innerText = error;
            throw new Error(error);
          }

          this.dialog = true;


          const authResponse = await fetch(this.scaUrl + '/sign/verify-signing/' + signItemId, {
            method: 'POST',
            credentials: 'include',
            // mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(asseResp),
          });

          const authResponseJSON = await authResponse.json();
          
          if (authResponseJSON && authResponseJSON.verified) {
            return true;
            
          } else {
            alert("Authentication Failed!");
          }
      },
      unmaskData: async function() {
        // this.awsRumClient.recordEvent('ButtonClicked_UNMASK', {
        //         action: "UNMASK_DATA",
        //         transactionType: this.transaction.transactionType,
        //         user_interaction: {
        //             interaction : "click",
        //         }            }
        // )

        if (!this.payLater) { // temporarily not showcasing login for pay later transactions
          let authenticated = false;
        authenticated = await this.authenticate();

        if (authenticated) {
          this.masked = false;
          this.getUser(this.masked);
        }
        }

      },
      pay: async function() {
        // this.awsRumClient.recordEvent('ButtonClicked:PAY', {
        //         transactionType: this.transaction.transactionType,
        //         user_interaction: {
        //             interaction_1 : "click",
        //         }            }
        // )
        


        const issuerId = this.getIssuerID(this.selectedIban);

        const request = {
          issuerId: issuerId,
          debtorIban: this.selectedIban,
          userName: this.userName,
        }

        console.log(request);

        const idealTrxResp = await fetch(this.bffUrl + '/pay-regular/' + this.trxId , {

          method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              
              body: JSON.stringify(request),

            });

        const idealTrx = await idealTrxResp.json();   
        const redirectUrl = idealTrx.redirectUrl;
        
        window.location.href = redirectUrl;
      },
      getPaymentDetails: async function() {
        

        const resp = await fetch(this.bffUrl + '/transactions/' + this.trxId);

        this.transaction = await resp.json();

        this.amount = this.transaction.amount.amount;
        this.orderAmount = this.transaction.amount.breakdown.orderAmount;
        this.shippingCost = this.transaction.amount.breakdown.shippingCost;
        this.discount = this.transaction.amount.breakdown.discount;
        const trxStatus = this.transaction.status;

        // if (this.transaction.transactionFlow == 'FAST_CHECKOUT') { 
        //   this.checkout = true; 
        // }
        
        if (this.transaction.transactionType == 'RECURRING') { // checkout with recurring
          this.recurring = true;
          this.transactionType = 'recurring'

          if (this.transaction.recurringPeriod == 'WEEKLY') {
          this.recurringPeriodLabel = '/we'
          }
          else if (this.transaction.recurringPeriod == 'MONTHLY') {
            this.recurringPeriodLabel = '/mo'
          } else if (this.transaction.recurringPeriod == 'YEARLY') {
            this.recurringPeriodLabel = '/yr'
          }
        } else if (this.transaction.transactionType == 'SCHEDULING') { // checkout with scheduling 
          this.transactionType = 'scheduled';
          this.recurring = true;

          this.ultimateExecutionDate = this.transaction.ultimateExecutionDate;

          if (this.ultimateExecutionDate != undefined) {
            this.scheduling = true;
            this.date = new Date();
            this.date.maxDate = new Date(this.ultimateExecutionDate);
          }
        } else if (this.transaction.transactionType == 'PAY_LATER') {
          this.payLater = true;
          this.payLaterSelected = true;
          this.transactionType = 'iDEAL in3';

          document.querySelector('body').setAttribute('style', 'background:#F8FBFC')


          if (trxStatus == 'PAY_LATER_ABORTED') {
            this.payLaterSelected = false;
            this.frequencySelection = true;
          } else if (trxStatus == 'PAY_LATER_REJECTED') {
            this.payLaterSelected = false;
            this.disableFrequencySelection = true;
            this.frequencySelection = true
          } else if(trxStatus == 'NEW') {
            this.frequencySelection = false;
          }
        }
        
      },
      selectIban: function(index) {
        this.selectedIban = this.bankAccounts[index].iban;
      },
      getIssuerColor: function(issuerName) {
        switch (issuerName) {
          case 'ABC Bank':
            return '#6944FF';
          case 'ING Bank':
            return '#FF4600';
          case 'Rabobank':
            return '#EE9A29';
          case 'Bunq':
            return '#000000'
        }
      },
      getIssuerLogo: function(issuerName) {
        switch (issuerName) {
          case 'ABC Bank':
            return logoIssuerABCBank;
          case 'ING Bank':
            return logoIssuerING;
          case 'Rabobank':
            return logoIssuerRabobank;
          case 'Bunq':
            return logoIssuerBunq;
        }
      },
      getIssuerLogoQuickHack: function(iban) {

        if (iban.includes('ABCB')) {
          return logoIssuerABCBank;
        } else if (iban.includes('INGB')) {
          return logoIssuerING;
        } else if (iban.includes('RABO')) {
          return logoIssuerRabobank;
        } else if (iban.includes('BUNQ')) {
          return logoIssuerBunq;
        } 
      },
      getIssuerID: function(iban) {

        if (iban.includes('ABCB')) {
          return 'ABCBNL2A';
        } else if (iban.includes('INGB')) {
          return 'INGBNL2A';
        } else if (iban.includes('RABO')) {
          return 'RABONL2U';
        } else if (iban.includes('BUNQ')) {
          return 'BUNQNL2A';
        } 
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main {
    position: absolute;
    /* top: 0px;
    left: 0px; */
    width: 100%;
  }

  p span {
    display: block;
    /* text-align: right; */
  }
  .content-wrapper {
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    max-width: 52em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .top-bar {
    /* justify-content: center;
    align-items: center; */
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 80px;
    display: flex;
    flex-direction: row;
    background: #C9DDE4;
    box-shadow: 0px 2px 6px rgba(109, 164, 182, 0.15);
  }
  .top-bar .ideal-logo {
    width: 50px;
  }
  .top-bar .content-wrapper .payment-details {
    text-align: right;
    /* margin-right: 90px; */
    height: 80px;
    padding-top: 16px;
    font-family: 'Montserrat';
  }
  .top-bar .content-wrapper .payment-details .amount {
    font-size: 28px;
  }
  .top-bar .content-wrapper .payment-details .merchant-name {
    font-size: 14px;
    margin-top: -6px;
  }
  .menu-bar {
    height: 50px;
    position: relative;
    padding-top: 70px;

  }
  .menu-bar .cancel-button {
    font-family: 'Montserrat';
    color: #CC0066;
    font-weight: 700;
    /* margin-top: -5px; */
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 100%;
    /* padding: 40px 16px; */
    margin-bottom: -50px;
    gap: 40px;
    /* background: #f8f8fc; */
    /* position: relative; */
  }

  .transaction-summary {
    background-color: #EEF5F7;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 393px;
    margin-bottom: -50px;
    gap: 24px;
    position: relative;
    padding: 16px;
  }

  .transaction-summary .heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 393px;
    text-align: left;
    margin-bottom: 0px;
    padding-left: 16px;
  }


  .checkout-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* padding: 40px 16px; */
    margin-bottom: -50px;
    gap: 40px;
    /* background: #f8f8fc; */
    position: relative;
  }

  .checkout-title .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 393px;
    max-width: 60em;
    text-align: left;
    margin-top: 50px;
    margin-left: 0px;
    margin-bottom: 0px;
  }


  .transaction-summary .order-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
    padding-bottom: 30px;
    gap: 24px;
    margin-top: 10px;
    /* margin-bottom: 10px; */

    width: 100%;

    /* Neutrals / White */

    background: #FFFFFF;
    /* Selector/Light theme / Default */

    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }


  .transaction-summary .order-summary .order-details {

    color: #283034;
    text-decoration: none;
    /* display: inline-block; */
    cursor: pointer;
    width: 100%;
    font-size: 0.9em;
    align-items: left;
    justify-content: left;
    text-align: left;
    padding: 0px;
    box-sizing: border-box;
    /* margin-bottom: -20px; */
    
  }

  .transaction-summary .order-summary .order-details .order-details-table {
    width: 100%;
  }

  .transaction-summary .order-summary .order-details .order-details-table .amounts {
    text-align: right;
  }
  .transaction-summary .order-summary .order-details .order-details-table .items {
    font-weight: 600;
  }
  .transaction-summary .order-details .divider {
    border: none; /* Removes default border */
    height: 1px; /* Sets the height of the line */
    background-color: #EEF5F7; /* Sets the color of the line */
  }

  .transaction-summary .order-summary .order-details .order-details-table .total {
    font-weight: 600;
    font-size: 20px;
  }

  .transaction-summary .button-payment {
    background-color: #cc0066;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 100px;
    width: 100%;
    font-size: 1em;
    height: 50px;
    align-items: center;
    justify-content: center;
    font-weight: 700;

  }

  .transaction-summary .sharing-description {
    /* width: 320px; */
    text-align: justify;
    padding: 10px;
  }


  
  .content .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 393px;
    max-width: 60em;
    text-align: left;
    margin-top: 50px;
    margin-bottom: -50px;
    
    
  }

  .content .heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 393px;
    text-align: left;
    margin-bottom: -20px;
  }

  .content .box-details {
    background-color: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;
    border: none;
    color: #283034;
    text-decoration: none;
    /* display: inline-block; */
    cursor: pointer;
    width: 393px;
    font-size: 0.9em;
    align-items: left;
    justify-content: left;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: -20px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    position: relative;
  }

  .content .box-details .icon-eye {
    position: absolute;
    left: 330px;
    width: 20px;
  }

  .content .box-details .icon-pencil {
    position: absolute;
    left: 360px;
    width: 20px;
  }

  .content .payment-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 32px;

    width: 311px;
    /* Light theme/Light */
    background: #F8FBFC;
    border-radius: 12px;
  }


  .blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }

  .content .scheduled-date {
    width: 393px;
    margin-top: 40px;

  }

  .content .sharing-description {
    width: 393px;
    /* text-align: justify; */
  }

  .box-information {
    
    padding: 16px;
    margin-top: -15px;
    width: 393px;
    /* Light theme/Medium */
    background: #DEEAEE;
    border-radius: 12px;
    text-align: left;
    box-sizing: border-box;
    position: relative;
  }

  .box-information .description .switch {
    position: absolute;
    right: 20px;
    top: 0px;
  }

  

  .content .payment-details .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 301px;
    text-align: left;
    margin-bottom: -10px;
  }




  .content .selected-iban {
    align-items: center;
    isolation: isolate;
    width: 100%;
    /* height: 42px; */
    background: #ffffff;
    /* Acct selector shadow */
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
  }

  .content .selected-iban .selected-iban-logo {
    padding: 3px;
  }
  .content .selected-iban .selected-iban-logo .icon {
    width: 40px;
    margin-bottom: -8px;
  }

  .content .selected-iban .selected-iban-text {
    font-size: 1.2em;    /* padding-right: 20px; */
  }

  .content .selected-iban .selected-iban-pencil {
    padding-top: 10px;
  }
  .content .selected-iban .selected-iban-pencil .icon {
    width: 21px;
  }


  .content .description {
    width: 393px;
    text-align: left;
  }
  .button-payment {
    background-color: #cc0066;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 100px;
    width: 393px;
    font-size: 1em;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .modal-in-progress {
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 393px;
    background-color: #FFFFFF;
    
  }

  .modal-in-progress .modal-description {
    padding-top: 20px;
  }
  
  hr.solid {
    border-top: 1px solid #eaf1f4;
  }

  



  @media screen and (min-width: 320px) {
    .button-preferred-bank {
      font-size: 0.9em;
      }

    .content {
      padding: 40px 0px;
      width: 100%;

    }
  .top-bar .payment-details {
    text-align: right;
    margin-right: 0px;
    margin-top: -80px;
    font-family: 'Montserrat';
  }
  .top-bar .payment-details .amount {
    font-size: 28px;
  }
  .top-bar .payment-details .merchant-name {
    font-size: 14px;
    margin-top: -25px;
  }
  }

  @media screen and (min-width: 640px) {
    .content-wrapper {
      /* display: flex; */
      padding: 15px;
      width: 100%;
      max-width: 52em;
    }

    .content {
      left: 50px;
    }

    .content .title {
      width: 806px;
      max-width: 60em;
    }

    .transaction-summary {
      left: -60px;
      top: 20px;
    }

    .checkout-title .title {
      margin-top: 50px;
      margin-left: -460px;
      margin-bottom: 0px;
    }

    .content .carousel .carousel-iban {
        top: 60px;
        left: 20px;

      }



  /* @media screen and (min-width: 480px) {
    .button-preferred-bank {
      font-size: 1em;
    }
  } */
}
  

</style>

<style>

  .dp__input {
      display:flex;
      align-items:center;
      text-align:left;
      justify-content:center;
      height:40px;
      padding:5px;
      padding-left: 40px;
      width: 393px;
      box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
      border-radius: 12px;
      box-sizing:border-box;
      position:relative;
      border: none;
  }

  .dp__select {
    color:#CC0066;
  }

  .dp__theme_light {
    --dp-primary-color: #CC0066;
  }
</style>
