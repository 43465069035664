<template>

    <PaymentCheckoutComponent />
    <BottombarComponent  />

</template>
<script>
// @ is an alias to /src
import PaymentCheckoutComponent from "@/components/PaymentCheckoutComponent.vue";
import BottombarComponent from "@/components/BottombarComponent.vue";


// this.awsRum.recordPageView({ pageId: '/checkout'})


export default {
  name: "paymentCheckoutPage",
  components: {
    PaymentCheckoutComponent,
    BottombarComponent
  }
};
</script>
<style scoped>
  body {
    background-color: #F8FBFC;
    font-family: 'Montserrat';
  }

</style>
