<template>
  <PaymentConfirmComponent />

</template>
<script>
// @ is an alias to /src
import PaymentConfirmComponent from "@/components/PaymentConfirmComponent.vue";



export default {
  name: "paymentConfirmPage",
  components: {
    PaymentConfirmComponent
  }
}
</script>
<style scoped>
  body {
    background-color: #eaf1f4;
    font-family: 'Montserrat';
  }

</style>
