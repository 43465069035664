<template>
    <v-overlay :value="isLoading">
        <v-progress-circular
        indeterminate
        color="primary"
        size="64"
        ></v-progress-circular>
    </v-overlay>
    <div class="content-container">
        <h2 class="left-align">Select Wallet</h2>
        <div class="wallet-list" v-if="relatedApps.length > 0">
            <h3 class="left-align">Detected wallets</h3>

            <!-- <h3>Your wallets</h3> -->
            <div v-for="(relatedApp, index) in relatedApps" :key="index" class="wallet-row">
                <div class="wallet left-align" :class="{ selected: selectedIndex === index }" @click="selectWallet(index, relatedApp, relatedApps, 'RECOGNIZED_WALLET')">
                    <!-- <div class="wallet-content"> -->
                        <img class="app-icon" :src="relatedApp.icon">
                        <span class="wallet-text">{{ relatedApp.name }}</span>
                    <!-- </div> -->
                </div>
            </div>
        </div>
        <div v-else>
           <div class="wallet-list" v-if="existingWalletApps.length > 0">
                <h3 class="left-align">Preferred wallets</h3>
                <div v-if="existingWalletApps.length == 1" class="left-align"> 
                    <v-checkbox v-model="suppressLanding" class="custom-checkbox" label="Next time, use below wallet directly"></v-checkbox>
                </div>
                
                <div v-for="(existingWalletApp, index) in existingWalletApps" :key="index" class="wallet-row">
                    <div class="wallet left-align" :class="{ selected: selectedIndex === index }" @click="selectWallet(index, existingWalletApp, existingWalletApps, 'PREFERRED_WALLET')">
                        <!-- <div class="wallet-content"> -->
                            <img class="app-icon" :src="existingWalletApp.icon">
                            <span class="wallet-text">{{ existingWalletApp.name }}</span>
                        <!-- </div> -->
                    </div>
                    <div class="remove-wallet-button" @click="deleteWallet(index, existingWalletApp)">                                    
                        <v-icon icon="fas fa-xmark"  />
                    </div>
                </div>
            </div>
        </div>     
        <div class="wallet-list">
                <!-- <h3 class="left-align" v-if="otherWalletApps.length > 0 && existingWalletApps.length > 0">Other wallets</h3> -->
                <!-- <div v-for="(otherWalletApp, index) in otherWalletApps" :key="index" class="wallet-row">
                    <div class="wallet left-align" :class="{ selected: selectedIndex === index }" @click="selectWallet(index, otherWalletApps, 'OTHER_WALLET')">
                            <img class="app-icon" :src="otherWalletApp.icon">

                            <span class="wallet-text">{{ otherWalletApp.name }}</span>
                    </div>
                </div> -->

                <h3 class="left-align">Other wallets</h3>

                <!-- Search Bar -->
                <input class="search-bar" type="text" v-model="searchQuery" placeholder="Search...">

                <!-- List of Apps -->
                <div v-for="(otherWalletApp, index) in filteredWalletApps" :key="index" class="wallet-row">
                <div class="wallet left-align" :class="{ selected: selectedIndex === index }" @click="selectWallet(index, otherWalletApp, otherWalletApps, 'OTHER_WALLET')">
                    <img class="app-icon" :src="otherWalletApp.icon">
                    <span class="wallet-text">{{ otherWalletApp.name }}</span>
                </div>
                </div>
            </div>   
    </div>
  </template>
  
  <script>
  import icon_abcbank from "@/assets/icon_app_abcbank.png";
  import icon_123bank from "@/assets/icon_app_123bank.png";
  import icon_abnamro from "@/assets/icon_app_abnamro.png";
  import icon_n26 from "@/assets/icon_app_n26.png";
  import icon_ing from "@/assets/icon_app_ing.png";
  import icon_revolut from "@/assets/icon_app_revolut.png";
  import icon_sparkassen from "@/assets/icon_app_sparkassen.png";
  import icon_deutschebank from "@/assets/icon_app_deutsche-bank.png";
//   import icon_commerzbank from "@/assets/icon_app_commerzbank.png";
  import icon_kbc from "@/assets/icon_app_kbc.png";
  import icon_bnp from "@/assets/icon_app_bnpparibas.png";
  import icon_creditagricole from "@/assets/icon_app_creditagricole.png";
  import icon_creditmutuel from "@/assets/icon_app_creditmutuel.png";
  import icon_labanquepostale from "@/assets/icon_app_labanquepostale.png";
  import icon_societegenerale from "@/assets/icon_app_societegenerale.png";    
  import icon_rabobank from "@/assets/icon_app_rabobank.png";
  import icon_dzbank from "@/assets/icon_app_dzbank.png";



  

  export default {
    data() {
      return {
        relatedApps: [],
        relatedAppsLabels: [
            { 
                id: 'com.siuwy.pulsar',
                name: 'ABC Bank',
                applink: 'pulsar://', // for demo purposes, just using url scheme in stead of app link,
                icon: icon_abcbank
            },
            {
                id: 'com.siuwy.skylark',
                name: '123 Bank',
                applink: 'skylark://', // for demo purposes, just using url scheme in stead of app link
                icon: icon_123bank
            },
            {
                id: '',
                name: 'Sparkasse',
                applink: '', 
                icon: icon_sparkassen

            },
            {
                id: '',
                name: 'DZ Bank',
                applink: '',
                icon: icon_dzbank

            },
            {
                id: '',
                name: 'Deutsche Bank',
                applink: '',
                icon: icon_deutschebank

            },
            {
                id: '',
                name: 'N26',
                applink: 'https://app.n26.com/link/ideal',
                icon: icon_n26

            },
            {
                id: '',
                name: 'Revolut',
                applink: 'https://oba.revolut.com/ui/index.html?response_type=code&request=eyJraWQiOiItVlpCVXU1VE9aclhPd01IQ2ZPVGFlVDdYWFUiLCJhbGciOiJQUzI1NiJ9.eyJjbGllbnRfaWQiOiI2YWZlMjFhYi04MGQzLTQ3OGEtYTRlNC02MDg3MGIyZTg5MWYiLCJyZXNwb25zZV90eXBlIjoiY29kZSIsInNjb3BlIjoicGF5bWVudHMiLCJzdGF0ZSI6ImNjZDliYzhiLTNlMTItNGFlZC1hZTdhLWQ5NDBjYjk4NThhMiIsInJlZGlyZWN0X3VyaSI6Imh0dHBzOi8vaWRlYWwucmV2b2x1dC5jb20vcGF5bWVudC1hdXRob3JpemVkIiwiY2xhaW1zIjp7ImlkX3Rva2VuIjp7Im9wZW5iYW5raW5nX2ludGVudF9pZCI6eyJ2YWx1ZSI6ImI0MDRkNWU1LTZmYzAtNDIwOS1iNTdlLTNjMzEwNmZmOWU0NiJ9fX19.jFfNJcqfMxTweJHZzdnpu93ZpsYGoiA1zX_R8WT2p2DmKYYoetjVnMtxj8gjja_BUnzwlcRuZFGEZ03jiXoMqadI0xst6tX2jZhM1vyLeGXIx0u6h-g1t5Qkow2JkYYPfGwNYGFxBSEGH_cPgjYi0SsdOtGSuCw3ZBmakML72oC-9-BU7M5dGsXnVYQeyjsYqUO4QTk6eSoGf2w1BdmUXL0UzYcoH2WYl44vqDcFCm4v_aourCn2oAaNlpS_ga2V4B4akRBLwuEtxhe8uyUVgOUUPc5cdre8K2lZB0N4jqi3ZgqypMKnsifquox0RTFPzjr22K6G9XCmjPdM86krFg&redirect_uri=https%3A%2F%2Fideal.revolut.com%2Fpayment-authorized&client_id=6afe21ab-80d3-478a-a4e4-60870b2e891f&state=ccd9bc8b-3e12-4aed-ae7a-d940cb9858a2&scope=payments', // app link of Revolut app (random iDEAL trx for demo purposes)
                icon: icon_revolut

            },
            {
                id: '',
                name: 'KBC',
                applink: '',
                icon: icon_kbc

            },
            {
                id: '',
                name: 'BNP Paribas',
                applink: '',
                icon: icon_bnp

            },
            {
                id: '',
                name: 'Crédit Agricole',
                applink: '',
                icon: icon_creditagricole

            },
            {
                id: '',
                name: 'Crédit Mutuel',
                applink: '',
                icon: icon_creditmutuel
            },
            {
                id: '',
                name: 'La Banque Postale',
                applink: '',
                icon: icon_labanquepostale

            },
            {
                id: '',
                name: 'Société Generale',
                applink: '',
                icon: icon_societegenerale

            },
            {
                id: 'com.abnamro.nl.mobile.payments',
                name: 'ABN AMRO',
                applink: 'https://www.abnamro.nl/nl/widgetdelivery/unauthenticated/ideal/dep/nl/index.html?randomizedstring=1452335634&trxid=8153798630636956', // app link of ABN AMRO app (random iDEAL trx for demo purposes)
                icon: icon_abnamro
            },
            {
                id: '',
                name: 'ING',
                applink: 'https://ideal.ing.nl/ing-app-ideal-issuing/qr?payloadUri=https://tx.ideal.nl/2/AQ6MYMRTF3AJIYIZSSXVYZWDHX4&sig=BGBCQEIIAXCR4GVTSLZOILYMAJZLBCYLNRCTRT4NOVADPLUWDO2W3O5T6DNMQEIDMXCHBZA3OEKHUWJFJVR6HNXCY2QTKHP5KS4WF4LZ26TA644O5GQ', // app link of ING app (random iDEAL trx for demo purposes)
                icon: icon_ing
            },
            {
                id: '',
                name: 'Rabobank',
                applink: '', 
                icon: icon_rabobank
            }

        ],
        walletUsersServiceUrl: process.env.VUE_APP_WALLET_USERS_SERVICE_URL,
        existingWalletApps: [],
        // existingWalletApps: [{name: "ABN AMRO", id: "com.abnamro.nl.mobile.payments", applink: "https://www.abnamro.nl/nl/widgetdelivery/unauthent…andomizedstring=1452335634&trxid=8153798630636956", icon: "/img/icon_app_abnamro.a781034f.png"}],
        otherWalletApps: [],
        // otherWalletApps: [{name: "N26", id: "com.abnamro.nl.mobile.payments", applink: "https://www.abnamro.nl/nl/widgetdelivery/unauthent…andomizedstring=1452335634&trxid=8153798630636956", icon: "/img/icon_app_abnamro.a781034f.png"}],
        suppressLanding: false,
        isLoading: true,
        searchQuery: ''
      };
    },
    mounted() {
        document.querySelector('body').setAttribute('style', 'background:#FFFFFF')
    },
    created() {
        this.checkRelatedApps();
        // this.getExistingWallets();

    },
    computed: {
        filteredWalletApps() {
        return this.otherWalletApps.filter(app => app.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
        }
    },
    methods: {
      async checkRelatedApps() {
        if ('getInstalledRelatedApps' in navigator) {
          const relatedApps = await navigator.getInstalledRelatedApps();
          console.log(relatedApps)
          this.relatedApps = relatedApps;

            // walk through the list of related apps and lookup the Name and the App link
            for (let x = 0; x < this.relatedApps.length; x++) {
                const matchingApp = this.relatedAppsLabels.find(app => app.id === this.relatedApps[x].id);

                if (matchingApp) {
                    this.relatedApps[x].name = matchingApp.name;
                    this.relatedApps[x].applink = matchingApp.applink;
                    this.relatedApps[x].icon = matchingApp.icon;
                }
            }

            // // It there is only 1 wallet, redirect directly to wallet
            // if (this.relatedApps.length == 1) {
            //     window.location.href = this.relatedApps[0].applink;
            // }
        } 
  
        const namesToCheck = this.relatedApps.map(item => item.name);

        // create a separate list of other wallets
        this.otherWalletApps = this.relatedAppsLabels.filter(item => !namesToCheck.includes(item.name));

        if (this.relatedApps.length == 0) {
            this.getWallets();
        }

  
        
      },
      async selectWallet(index, walletApp, walletApps, walletType) {
        if (walletType == 'OTHER_WALLET') {
            this.suppressLanding = false;
        }

        // delete walletApps[index].icon;

        // Add wallet to cookie
        const request = {
            // selectedWallet: walletApps[index],
            selectedWallet: walletApp,
            suppressLanding: this.suppressLanding
        } 

        console.log(this.suppressLanding);

        const storeWalletResp = await fetch(this.walletUsersServiceUrl + '/users-wallets', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(request),

        });
        const storedWalletResp = await storeWalletResp.json();   

        console.log(storedWalletResp);

        // window.location.href = walletApps[index].applink;
        window.location.href = walletApp.applink;

      },
      async getWallets() {

        // lookup if there are existing wallets stored based on cookie
        const resp = await fetch(this.walletUsersServiceUrl + '/users-wallets/', {
            method: 'GET',
            credentials: 'include',
        });

        if (resp.status === 303) {
            console.log('303 received, redirecting')
            window.location.href = resp.headers.get('Location');
        }


        const existingWalletAppsResp = await resp.json();


        if(existingWalletAppsResp.wallets) {
            this.existingWalletApps = existingWalletAppsResp.wallets;
            this.suppressLanding = existingWalletAppsResp.suppressLanding;

            

            for (let x=0 ; x<this.existingWalletApps.length ; x++) {
                const matchingApp = this.relatedAppsLabels.find(app => app.name === this.existingWalletApps[x].name);

                if (matchingApp) {
                    this.existingWalletApps[x].icon = matchingApp.icon;
                }
            }


            
            if (this.suppressLanding == true) {
                window.location.href = this.existingWalletApps[0].applink;
            } 


            const namesToCheck = existingWalletAppsResp.wallets.map(item => item.name);

            // create a separate list of other wallets
            this.otherWalletApps = this.relatedAppsLabels.filter(item => !namesToCheck.includes(item.name));
        }             
        else {
            this.otherWalletApps = this.relatedAppsLabels
        }
        
        this.isLoading = false;
      }, 
      async deleteWallet(index, walletApps) {
        console.log(walletApps)


        const deleteWalletResp = await fetch(this.walletUsersServiceUrl + '/users-wallets?name=' + walletApps.name, {
            method: 'DELETE',
            headers: {
            'Content-Type': 'application/json',
            },
            credentials: 'include',
        });
        const deletedWalletResp = await deleteWalletResp.json();   

        console.log(deletedWalletResp);

        this.getWallets();
      }
    }
  };
  </script>
  
  <style scoped>
    h3 {
        margin: 40px 0 0;
    }


    .content-container {
        max-width: 400px;
        padding: 20px;
        margin: 0 auto;
    }


    .left-align {
        text-align: left;
    }
    .right-align {
        text-align: right;
    }


    .wallet-list {
        /* max-height: calc(100vh - 80px);  */
        /* overflow-y: auto; */
        /* overflow: visible; */
        height: auto;
        padding-top: 5px;
    }

    .wallet-row {
        height: 60px;
        padding: 10px;
        /* border-bottom: 1px solid #ccc; */
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #F7F6F3;
        border-radius: 10px;
        margin-bottom: 10px;
        font-weight: 700;


    }

    .remove-wallet-button {
        margin-left: 0px;
        /* flex-grow: 1; */
    }

    .search-bar {
        width: 98%;
        height: 48px;
        border-radius: 24px;
        border: 1px solid #ccc;
        margin-bottom: 20px;
        margin-top: 20px;

    }

    input {
        padding: 20px; /* Adds padding to the input, affecting placeholder indirectly */
        /* Other styling for the input */
    }

    input::placeholder {
        color: #ccc;
        font-size: 16px; /* Adjust as needed */
        /* Other styles for the placeholder text, but note that padding cannot be directly applied */
    }
    .wallet {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

    .wallet-text {
        /* flex-grow: 1; */
        line-height: 40px;
        padding-left: 10px;
        width: 80%;
    }
    /* .custom-checkbox-text {
        font-size: 0.75em;
    } */
    .custom-checkbox {
        font-size: 0.8em;
        margin-left: -10px;
    }

    .app-icon {
        width: 40px;
    }
    /* .custom-checkbox {
        appearance: none;
        background-color: #fff;
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid currentColor;
        border-radius: 0.15em;
        transform: translateY(-0.075em);
    } */
  </style>
  