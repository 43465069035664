<template>
    <div class="main">
    <div class="top-bar">
      <div class="content-wrapper">
        <img class="ideal-logo" src="../assets/ideal-logo.png">
      </div>
      
    </div>
    <div class="menu-bar">

    </div>
    <LoginComponent class="login" />
    
    <BottombarComponent  />
  
</div>
</template>
<script>
// @ is an alias to /src
import LoginComponent from "@/components/LoginComponent.vue";
import BottombarComponent from "@/components/BottombarComponent.vue";

export default {
  name: "loginPage",
  components: {
    LoginComponent,
    BottombarComponent
  }
};
</script>
<style scoped>
     .main {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
  .content-wrapper {
    padding: 15px;
    width: 100%;
    max-width: 52em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  a {
    text-decoration:none;
  }

  .icon-backarrow {
    height: 30px;
    margin-top: -5px;
  }
  .top-bar {
    /* justify-content: center;
    align-items: center; */
    position: relative;
    height: 80px;
    background: #C9DDE4;
    box-shadow: 0px 2px 6px rgba(109, 164, 182, 0.15);
  }
  .top-bar .ideal-logo {
    width: 50px;
  }
  .menu-bar {
    height: 50px;
  }
  .menu-bar .cancel-button {
    font-family: 'Montserrat';
    color: #CC0066;
    font-weight: 700;
    /* margin-top: -5px; */
  }
</style>
