<template>
  <div class="container-register">
    <!-- <div class="content-description"> -->
    <!-- <div class="content"> -->
      <div class="title">
        Create your profile
      </div>
      <div class="description"> 
        We will use your email to inform you about your orders and when our Terms and Conditions are updated.
      </div>
      <!-- <div class="block"> 
        <div class="label">First name</div>
        <input class="input" v-model="firstName">
      </div>
      <div class="block"> 
        <div class="label">Last name</div>
        <input class="input" v-model="lastName">
      </div> -->
      <!-- <div class="block">  -->
        <div class="errorMsg" v-show="showErrorMsg">Username is already in use</div>
        <div class="label">Email address</div>
        <input class="input" v-model="email">
      <!-- </div> -->
      <!-- <div class="block"> -->
        <button v-on:click="registerEmail" class="button-register">Next</button>
      <!-- </div> -->
    </div>
    <!-- </div> -->
  <!-- </div>  -->
</template>

<script>
import { emitter } from "../event-bus.js";

  

  export default {
    name: "RegisterBiometrics",
    data() {
      return {
        result: false,
        registrationSuccess: false,
        title: "Register",
        resultMessage: "",
        loading: false,
        scaUrl: process.env.VUE_APP_SCA_URL,
        // firstName: '',
        // lastName: '',
        email: '',
        showErrorMsg: false
      }
    },
    methods: {
      registerEmail: async function() {
        
        let request = { 
          email: this.email
        }
        

        const resp = await fetch(this.scaUrl + '/users/identify', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
          });
        const retrievedUser = await resp.json();
        console.log(retrievedUser);

        // if (retrievedUser.userId != this.email) {
          // console.log(this.email);
          emitter.emit('email_filled', this.email);
          window.location.href =  "/#/registration/biometrics";
        // } else {
        //   this.showErrorMsg = true;
        // }

        

      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .title {
    width: 343px;
    height: 36px;

    /* H4/Small */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */

    text-align: left;

    /* Neutrals/Black */

    color: #080808;
    /* margin-bottom: -30px; */
    margin-left: auto;
    margin-right: auto;
  }
  .container-register {
        /* display: flex;
    flex-direction: column;



    background: #F8FBFC;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; */
    align-items: center;
    /* text-align: center; */
    /* width: 343px; */
    /* height: 536px; */
    background: #F8FBFC;
    padding: 24px 10px 40px;
    /* padding-top: 20px; */
    /* width: 343px; */
    gap: 40px;
    margin-left: auto;
    margin-right: auto;
    /* width: 100%; */
    display: block;
  }
 

  .block {
    margin-bottom: -20px;
    width: 343px;
  }
  .description {
    width: 343px;
    height: 72px;
    /* Body 1/Small */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    text-align: justify;
    /* or 150% */
    /* Grey/Darkest */
    color: #283034;
    /* Inside auto layout */
    /* margin-bottom: -40px; */
    margin-left: auto;
    margin-right: auto;
  }
  .label {
    width: 343px;
    height: 18px;
    text-align: left;

    /* Caption */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */


    /* Grey/Base */

    color: #525C62;
    margin-left: auto;
    margin-right: auto;
  }
  .input {
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;

    width: 343px;
    height: 40px;

    /* Neutrals / White */

    background: #FFFFFF;
    /* Light theme/Base */

    border: 1px solid #BFD7DF;
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .errorMsg {
    width: 343px;
    height: 18px;
    text-align: left;

    /* Caption */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */
    margin-left: auto;
    margin-right: auto;

    /* Grey/Base */

    color: #ED230D;
  }
  .button-register {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 10px;

    width: 343px;
    height: 40px;

    /* Primary / Base */
    color: #ffffff;
    background: #CC0066;
    border-radius: 100px;
    border: 0px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  hr.solid {
    border-top: 1px solid #eaf1f4;
  }
  @media screen and (min-width: 320px) {
    .button-preferred-bank {
      font-size: 0.9em;
    }
    .content-description {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media screen and (min-width: 480px) {
    .button-preferred-bank {
      font-size: 1em;
    }
  }
  

</style>
