<template>
  <!-- <div class="main"> -->
    <div class="top-bar">
        <div class="content-wrapper">
          <img class="ideal-logo" src="../assets/ideal-logo.png">
          <div class="payment-details">
            <p >
              <span class="amount">€ {{ amount }}</span>
              <span class="merchant-name">{{ transaction.creditorName }}</span>
            </p>
          </div>
        </div>
        
    </div>
    <div class="menu-bar">
      <div class="content-wrapper">
        <!-- <a :href="merchantUrl + '/#/login'"> -->
          <div class="cancel-button">
            Cancel
          </div>
        <!-- </a> -->
      </div>
    </div>
      <div class="checkout-title">
        <div v-show="!showPayLater" class="title">
          Checkout
        </div>
        <div v-show="showPayLater" class="title">
          iDEAL in3
        </div>
      </div>
      <div class="container-wrapper">
        <v-container>
          <v-row>
            <v-col>
              <div class="content">
            
                <div class="heading"> 
                  Your details
                </div>
                <div class="box-details"> 
                  <img v-show="showUnmaskIcon" v-on:click="unmaskData" class="icon-eye" src="../assets/icon_eye.png">
                  <img class="icon-pencil" src="../assets/icon_pencil.png">
                  <div><b>Contact information</b></div><br>
                  <div  v-show="showUnmaskIcon"><span>{{phoneNumberPart1}}</span><span class="blur">{{phoneNumberPart2}}</span><span>{{phoneNumberPart3}}</span><br></div>
                  <div v-show="!showUnmaskIcon">{{phoneNumber}}</div>
                  {{userName}}
                </div>
                <div v-show="!showRecurring">
                  <div class="box-details">
                    <img v-show="showUnmaskIcon" v-on:click="unmaskData" class="icon-eye" src="../assets/icon_eye.png">
                    <img class="icon-pencil" src="../assets/icon_pencil.png">
                    <div><b>Delivery address</b></div><br>
                    <div v-show="showUnmaskIcon"> 
                      {{deliveryFirstName}} {{deliveryLastNamePart1}}<span class="blur">{{deliveryLastNamePart2}}</span><br>
                      <span class="blur">{{deliveryStreetname}}</span> {{deliveryHouseNumber}}<br>
                      <span class="blur">{{deliveryPostalcodePart1}}</span><span>{{deliveryPostalcodePart2}}</span> {{deliveryCity}}
                    </div>
                    <div v-show="!showUnmaskIcon"> 
                      {{deliveryFirstName}} {{deliveryLastName}}<br>
                      {{deliveryStreetname}} {{deliveryHouseNumber}}<br>
                      {{deliveryPostalcode}} {{deliveryCity}}
                    </div>
                  </div>
                </div>
                <div class="box-details"> 
                  <div v-show="showRecurring">
                    <img v-show="showUnmaskIcon" v-on:click="unmaskData" class="icon-eye" src="../assets/icon_eye.png">
                  </div>
                  <img class="icon-pencil" src="../assets/icon_pencil.png">
                  <div><b>Invoicing address</b></div><br>
                  <div v-show="!showRecurring">
                    Same as delivery address
                  </div>
                  <div v-show="showRecurring"> 
                    <div v-show="showUnmaskIcon"> 
                      {{invoicingFirstName}} {{invoicingLastNamePart1}}<span class="blur">{{invoicingLastNamePart2}}</span><br>
                      <span class="blur">{{invoicingStreetname}}</span> {{invoicingHouseNumber}}<br>
                      <span class="blur">{{invoicingPostalcodePart1}}</span><span>{{invoicingPostalcodePart2}}</span> {{invoicingCity}}
                    </div>
                    <div v-show="!showUnmaskIcon"> 
                      {{invoicingFirstName}} {{invoicingLastName}}<br>
                      {{invoicingStreetname}} {{invoicingHouseNumber}}<br>
                      {{invoicingPostalcode}} {{invoicingCity}}
                    </div>

                  </div>
                </div>
              </div>
            </v-col>
            <v-col>
              <div v-show="!showPayLater" class="content"> 
                <!-- <div class="payment-details">  -->
                    <div class="heading">Selected account</div>
                    <!-- <div class="selected-iban">
                      <img class="icon-issuer" src="../assets/icon-123bank.png">
                      <div class="issuer-details"> 
                        <div class="issuer-name">{{issuer}}</div>
                      <div class="iban"><span>{{ibanPart1}}</span><span class="blur">{{ibanPart2}}</span><span>{{ibanPart3}}</span></div>
                      </div>
                    </div> -->
                    
                    <div class="carousel"> 
                      <Splide ref="splide" @splide:move="onSplideMove" :options="splideOptions" >
                        <SplideSlide v-for="bankAccount in bankAccounts" :key="bankAccount">
                          <div class="carousel-iban" v-bind:style="{ background: getIssuerColor(bankAccount.issuer) }"> 
                            <img :src="getIssuerLogo(bankAccount.issuer)" class="icon-issuer">
                            <div class="issuerName">{{bankAccount.issuer}}</div>
                            <div class="iban">{{bankAccount.iban}}</div>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                  <div v-show="showScheduling"> 
                    <div class="heading">Billing date</div>
                    <div class="scheduled-date">
                      <Datepicker v-model="date" :format="format" :enableTimePicker="false" :minDate="new Date()" :maxDate="ultimateExecutionDate" v-bind:clearable="false" ></Datepicker>
                    </div>
                  </div>

                  <div v-show="showScheduling || showRecurring" class="box-information">
                    <div class="description">This is a <b>{{transactionType}} payment</b></div>
                  </div>

                  <div class="heading">Order summary</div>
                  <div class="order-details"> 
                    <table class="order-details-table">
                      <tr v-show="!showRecurring" class="items"><td>Items</td><td class="amounts">€{{orderAmount}} </td></tr>
                      <tr v-show="showRecurring" class="items"><td>{{transaction.description}}</td><td class="amounts">€{{orderAmount}}{{recurringPeriodLabel}} </td></tr>

                      <tr v-show="!showRecurring"><td>Delivery costs</td><td class="amounts">€{{ shippingCost}} </td></tr>
                      <tr v-show="showRecurring"><td>Discount</td><td class="amounts">€{{ discount}} </td></tr>
                      <tr class="total"><td>Total</td><td class="amounts">€{{amount}} </td></tr>
                    </table>

                  </div>
                
                  <button v-on:click="pay"  class="button-payment">Sign in and Pay</button>
                  <div class="sharing-description">
                    <p>To complete your order, iDEAL will share your delivery details with the webshop.</p><br/>
                    <div v-show="showRecurring">
                      <p>By completing this payment. Consent will be given to {{ transaction.creditorName }} to deduct money on the selected bank account on the above agreed period.</p>
                    </div>
                  </div>
                  
              </div>
                <!-- <div class="payment-details">  -->
            </v-col>
            <div v-show="showPayLater" class="transaction-summary"> 
                <div class="heading">Transaction Summary</div>
                <div class="payment-frequency"> 
                  <div class="title">Payment Frequency</div>
                  <v-col v-show="frequencySelection" >
                    <v-radio-group class="frequency-selection" v-model="payLaterSelected" :disabled="disableFrequencySelection">
                      <v-radio color="#6DA4B6" label="Pay in 3 instalments" :value="true"></v-radio>
                      <v-radio color="#6DA4B6" label="Pay once" :value="false"></v-radio>
                    </v-radio-group>

                    <div v-show="!payLaterSelected" class="selected-account-title">Selected account</div>                    
                    <!-- <div v-show="!payLaterSelected" class="carousel">
                      <Splide ref="splide" @splide:move="onSplideMove" :options="splideOptions" >
                        <SplideSlide v-for="bankAccount in bankAccounts" :key="bankAccount">
                          <div class="carousel-iban" v-bind:style="{ background: getIssuerColor(bankAccount.issuer) }"> 
                            <img :src="getIssuerLogo(bankAccount.issuer)" class="icon-issuer">
                            <div class="issuerName">{{bankAccount.issuer}}</div>
                            <div class="iban">{{bankAccount.iban}}</div>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div> -->
                    <!-- <div  v-show="!payLaterSelected"> -->
                      <!-- <select class="iban-select" v-model="selectedIban"> 
                        <option v-for="bankAccount in bankAccounts" :key="bankAccount.iban" :value="bankAccount.iban">{{ bankAccount.iban }}</option>
                      </select> -->
                      <!-- <v-select id="ibanSelect" class="iban-select" v-model="selectedIban" :items="ibans" variant="outlined">
                        <template v-slot:selection="{ item }"><img :src="getIssuerLogoQuickHack(item.value)" class="icon-issuer">{{  item.value }}</template>                        
                      </v-select> -->
                    <!-- </div> -->
                    <div v-show="!payLaterSelected">
                      <v-menu>
                        <template v-slot:activator="{ props }">
                          <v-btn class="dropdown-select-box"
                            
                            v-bind="props"
                          >
                          <div class="selected-item">
                              <img :src="getIssuerLogoQuickHack(selectedIban)" class="icon-issuer-selected">
                              <div class="text">{{ selectedIban }}</div>
                              <i class="arrow down"></i>
                            </div>
                          </v-btn>
                        </template>
                        <v-list class="dropdown">
                          <v-list-item 
                            v-for="(bankAccount, index) in bankAccounts"
                            :key="index"
                            :value="index"
                            v-on:click="selectIban(index)"
                          >
                            <div class="dropdown-item">
                              <img :src="getIssuerLogo(bankAccount.issuer)" class="icon-issuer-dropdown">
                              <div class="text">{{ bankAccount.iban }}</div>
                            </div>

                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col v-show="!frequencySelection"> 
                    <p>Pay in 3 instalments</p>
                  </v-col>
                </div>


                  <div class="order-summary">
                    <div class="order-summary-title">Order summary</div>
                  <div class="order-details"> 
                    <table class="order-details-table">
                      <tr v-show="!showRecurring" class="items"><td>Items</td><td class="amounts">€{{orderAmount}} </td></tr>
                      <tr v-show="showRecurring" class="items"><td>{{transaction.description}}</td><td class="amounts">€{{orderAmount}}{{recurringPeriodLabel}} </td></tr>

                      <tr v-show="!showRecurring"><td>Delivery costs</td><td class="amounts">€{{ shippingCost}} </td></tr>
                      <tr v-show="showRecurring"><td>Discount</td><td class="amounts">€{{ discount}} </td></tr>
                      <tr class="total"><td>Total</td><td class="amounts">€{{amount}} </td></tr>
                    </table>

                  </div>

                </div>

                <!-- <button v-show="!payLaterSelected" v-on:click="pay"  class="button-payment">Sign in and Pay</button> -->
                <button v-show="!payLaterSelected" class="button-payment">Complete payment</button>

                <button v-show="payLaterSelected" v-on:click="payWithPayLater"  class="button-payment">Pay with iDEAL in3</button>
                <div class="sharing-description">
                  To complete your order, iDEAL will share your delivery details with the webshop
                </div>

                
              </div>
          </v-row>
        </v-container>
    </div>
    <v-dialog
      v-model="dialog"
      :scrim="true"
      persistent
    >
      <div class="modal-in-progress">

          <v-progress-circular
            indeterminate
            color="blue-lighten-3"
            model-value="20"
            :size="128"
            :width="8"
          ></v-progress-circular>
          <p class="modal-description">Please wait</p>

      </div>
    </v-dialog>
    <!-- <v-overlay v-model="dialog"></v-overlay> -->
    <!-- </div> -->
  <!-- </div> -->
</template>

<script>
  // import axios from "axios";
  const SimpleWebAuthnBrowser = require('@simplewebauthn/browser');
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import { ref } from 'vue';
  import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import '@splidejs/vue-splide/css';
  import logoIssuerABCBank from "../assets/icon-issuer-abcbank.png";
  import logoIssuerING from "../assets/icon-issuer-ing.png";
  import logoIssuerRabobank from "../assets/icon-issuer-rabobank.png";
  import logoIssuerBunq from "../assets/icon-issuer-bunq.png";


  export default {
    name: "PaymentComponent",
    components: { Datepicker,
      Splide,
      SplideSlide },
    data() {
      return {
        title: "Payment",
        scaUrl: process.env.VUE_APP_SCA_URL,
        bffUrl: process.env.VUE_APP_BFF_URL,
        trxId: this.$route.params.transactionId,
        userName: 'jiri.oen@gmail.com',
        amount: 0,
        orderAmount: 0,
        shippingCost: 0,
        discount: 0,
        merchantName: '',
        // date: null,
        transaction: {},
        deliveryFirstName: '',
        deliveryLastName: '',
        deliveryStreetname: '',
        deliveryHouseNumber: '',
        deliveryPostalcode: '',
        deliveryCity: '',
        deliveryCountry: '',
        invoicingFirstName: '',
        invoicingLastName: '',
        invoicingStreetname: '',
        invoicingHouseNumber: '',
        invoicingPostalcode: '',
        invoicingCity: '',
        invoicingCountry: '',
        iban: '',
        issuer: '',
        phoneNumber: '',
        recurringPeriodLabel: '',
        masked: true,
        scheduling: false,
        transactionType: '',
        recurring: false,
        payLater: false,
        payLaterSelected: false,
        selectedIban: '',
        bankAccounts: [],
        dialog: false,
        ibans: [],
        disableFrequencySelection: false,
        frequencySelection: false,
        awsRumClient: this.awsRum
      }
    },
    created() {
      this.getUser(this.masked);
      this.getPaymentDetails();


    },
    setup() {
        const date = ref(new Date());
        
        // In case of a range picker, you'll receive [Date, Date]
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        }

        const splide = ref();

        const splideOptions = { 
          arrows: true, 
          perPage: 1,
          gap: '1.2rem', 
          // padding: '30%',
          height: '190px', 
          fixedWidth: '393px', 
          direction: 'ttb',
          // fixedWidth: 311, 

          // isNavigation: true,
          breakpoints: {
            640: {
              // perPage: 1, 
              // trimSpace: true, 
              // fixedHeight: '72px',
              gap: '1.2rem', 
              // padding: '20%',
              height: '100px',
              fixedWidth: '390px',
              arrows: false 

            },
          }
        }
        
        return {
            date,
            format,
            splide,
            splideOptions
        }
    },
    computed: {
      showCheckout() {
        return this.checkout;
      },
      showRecurring() {
        return this.recurring;
      },
      showPayLater() {
        return this.payLater;
      },

      showUnmaskIcon() {
        return this.masked; 
      },
      showScheduling () {
        return this.scheduling;
      },
    },
    methods: {
      getUser: async function(masked) {
        let request = { 
          email: this.userName,
          masked: masked
        }

        const resp = await fetch(this.bffUrl + '/users/identify', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
          });
        const retrievedUser = await resp.json();
        // console.log(retrievedUser);
        
        this.deliveryAddressId = retrievedUser.addresses[0].addressId;
        this.deliveryFirstName = retrievedUser.addresses[0].firstName;
        this.deliveryLastName = retrievedUser.addresses[0].lastName;
        this.deliveryStreetname = retrievedUser.addresses[0].streetName;
        this.deliveryHouseNumber = retrievedUser.addresses[0].houseNumber;
        this.deliveryPostalcode = retrievedUser.addresses[0].postalCode;
        this.deliveryCity = retrievedUser.addresses[0].city;
        this.deliveryCountry = retrievedUser.addresses[0].country;

        this.invoicingAddressId = retrievedUser.addresses[0].addressId;
        this.invoicingFirstName = retrievedUser.addresses[0].firstName;
        this.invoicingLastName = retrievedUser.addresses[0].lastName;
        this.invoicingStreetname = retrievedUser.addresses[0].streetName;
        this.invoicingHouseNumber = retrievedUser.addresses[0].houseNumber;
        this.invoicingPostalcode = retrievedUser.addresses[0].postalCode;
        this.invoicingCity = retrievedUser.addresses[0].city;
        this.invoicingCountry = retrievedUser.addresses[0].country;

        this.iban = retrievedUser.bankAccounts[0].iban;
        
        this.selectedIban = retrievedUser.bankAccounts[0].iban;
        this.bankAccounts = retrievedUser.bankAccounts;

        for (let x=0; x < this.bankAccounts.length; x++) {
          this.ibans.push(this.bankAccounts[x].iban)
        }

        this.issuer = retrievedUser.bankAccounts[0].issuer;
        this.phoneNumber = retrievedUser.phoneNumber;
        
        this.checkout = false;
        // this.recurring = false;

        if (masked) {
          this.phoneNumberPart1 = this.phoneNumber.substring(0, 4);
          this.phoneNumberPart2 = this.phoneNumber.substring(4, 8);
          this.phoneNumberPart3 = this.phoneNumber.substring(8, 12);

          this.deliveryLastNamePart1 = this.deliveryLastName.substring(0,1)
          this.deliveryLastNamePart2 = this.deliveryLastName.substring(1,this.deliveryLastName.length-1);
          this.deliveryPostalcodePart1 = this.deliveryPostalcode.substring(0, 4)
          this.deliveryPostalcodePart2 = this.deliveryPostalcode.substring(5, 7)          

          this.invoicingLastNamePart1 = this.invoicingLastName.substring(0,1)
          this.invoicingLastNamePart2 = this.invoicingLastName.substring(1,this.invoicingLastName.length-1);
          this.invoicingPostalcodePart1 = this.invoicingPostalcode.substring(0, 4)
          this.invoicingPostalcodePart2 = this.invoicingPostalcode.substring(5, 7)
          
          this.ibanPart1 = this.iban.substring(0, 8);
          this.ibanPart2 = this.iban.substring(8, 14);
          this.ibanPart3 = this.iban.substring(14, 18);
        }
      },
            // eslint-disable-next-line no-unused-vars
      onSplideMove: function(newIndex, currentIndex, prevIndex) {
        this.selectedIban = this.bankAccounts[currentIndex].iban
      },
      authenticate: async function() {


          const { startAuthentication } = SimpleWebAuthnBrowser;
          const resp = await fetch(this.scaUrl + '/generate-authentication-options');

          let asseResp;
          try {
            const opts = await resp.json();
            console.log('authentication options', opts);
            
            asseResp = await startAuthentication(opts);

          } catch (error) {
            // elemError.innerText = error;
            throw new Error(error);
          }

          

          console.log(asseResp);
          
          try {
            const authResponse = await fetch(this.scaUrl + '/verify-authentication', {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(asseResp),
            });

            const authResponseJSON = await authResponse.json();
            console.log(authResponseJSON);
            

            if (authResponseJSON && authResponseJSON.verified) {
              this.$loggedIn = true;
              return true;
              
            } else {
              alert("Authentication Failed!");
            }            
          }catch (error) {
            // elemError.innerText = error;
            throw new Error(error);
          }

      },
      authorisePayment: async function(signItemId) {


          const { startAuthentication } = SimpleWebAuthnBrowser;
          const resp = await fetch(this.scaUrl + '/sign/generate-signing-options/' + signItemId);

          let asseResp;
          try {
            const opts = await resp.json();            
            asseResp = await startAuthentication(opts, false);

          } catch (error) {
            // elemError.innerText = error;
            throw new Error(error);
          }

          this.dialog = true;


          const authResponse = await fetch(this.scaUrl + '/sign/verify-signing/' + signItemId, {
            method: 'POST',
            credentials: 'include',
            // mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(asseResp),
          });

          const authResponseJSON = await authResponse.json();
          
          if (authResponseJSON && authResponseJSON.verified) {
            return true;
            
          } else {
            alert("Authentication Failed!");
          }
      },
      unmaskData: async function() {
        // this.awsRumClient.recordEvent('ButtonClicked_UNMASK', {
        //         action: "UNMASK_DATA",
        //         transactionType: this.transaction.transactionType,
        //         user_interaction: {
        //             interaction : "click",
        //         }            }
        // )

        if (!this.payLater) { // temporarily not showcasing login for pay later transactions
          let authenticated = false;
        authenticated = await this.authenticate();

        if (authenticated) {
          this.masked = false;
          this.getUser(this.masked);
        }
        }

      },
      pay: async function() {
        // this.awsRumClient.recordEvent('ButtonClicked:PAY', {
        //         transactionType: this.transaction.transactionType,
        //         user_interaction: {
        //             interaction_1 : "click",
        //         }            }
        // )
        
        let selectedExecutionDate = ''
        if (this.scheduling) {
          selectedExecutionDate = this.date.getFullYear() + '-' + (this.date.getMonth()+1) + '-' + this.date.getDate();
        }

        const request = {
          executionDate: selectedExecutionDate,
          debtorIban: this.selectedIban,
          userName: this.userName,
        }

        console.log(request);

        const signTrxResp = await fetch(this.bffUrl + '/pay/' + this.trxId , {

          method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              
              body: JSON.stringify(request),

            });

        const signItemTrxResp = await signTrxResp.json();   
        const signed = await this.authorisePayment(signItemTrxResp.signItemId)
        
        // console.log(signed);
        if (signed) {
          const resp = await fetch(this.bffUrl + '/transactions/' + this.trxId);

          const transactionResp = await resp.json();
          this.transaction = transactionResp;

          this.dialog = false;


          if (this.transaction.status == 'PAYMENT_AUTHORISED' || this.transaction.status == 'PAYMENT_EXECUTED') {
            this.$loggedIn = true;
            const returnUrl = this.transaction.returnUrl + "?idealTransactionId=" + this.trxId
            window.location.href = returnUrl;
          }
           
            
        }
      },
      payWithPayLater: async function() {

        // this.awsRumClient.recordEvent('ButtonClicked_PAY', {
        //         action: "INITIATE_PAYMENT",
        //         transactionType: this.transaction.transactionType,
        //         user_interaction: {
        //             interaction : "click",
        //         }            }
        // )

        // in3 logic goes here
        const returnUrl = 'https://' + window.location.host + '/#/paymentconfirm/' + this.trxId;
        const request = {
          userName: this.userName,
          returnUrl: returnUrl
        }

        const payLaterTrxResp = await fetch(this.bffUrl + '/pay-later/' + this.trxId , {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              
              body: JSON.stringify(request),

            });
            
            const payLaterTrx = await payLaterTrxResp.json();

            console.log(payLaterTrx)
            window.location.href = payLaterTrx.redirectUrl;


      },
      getPaymentDetails: async function() {
        

        const resp = await fetch(this.bffUrl + '/transactions/' + this.trxId);

        this.transaction = await resp.json();

        this.amount = this.transaction.amount.amount;
        this.orderAmount = this.transaction.amount.breakdown.orderAmount;
        this.shippingCost = this.transaction.amount.breakdown.shippingCost;
        this.discount = this.transaction.amount.breakdown.discount;
        const trxStatus = this.transaction.status;

        if (this.transaction.transactionFlow == 'FAST_CHECKOUT') { // standard checkout
          this.checkout = true; 
        }
        
        if (this.transaction.transactionType == 'RECURRING') { // checkout with recurring
          this.recurring = true;
          this.transactionType = 'recurring'

          if (this.transaction.recurringPeriod == 'WEEKLY') {
          this.recurringPeriodLabel = '/we'
          }
          else if (this.transaction.recurringPeriod == 'MONTHLY') {
            this.recurringPeriodLabel = '/mo'
          } else if (this.transaction.recurringPeriod == 'YEARLY') {
            this.recurringPeriodLabel = '/yr'
          }
        } else if (this.transaction.transactionType == 'SCHEDULING') { // checkout with scheduling 
          this.transactionType = 'scheduled';
          this.recurring = true;

          this.ultimateExecutionDate = this.transaction.ultimateExecutionDate;

          if (this.ultimateExecutionDate != undefined) {
            this.scheduling = true;
            this.date = new Date();
            this.date.maxDate = new Date(this.ultimateExecutionDate);
          }
        } else if (this.transaction.transactionType == 'PAY_LATER') {
          this.payLater = true;
          this.payLaterSelected = true;
          this.transactionType = 'iDEAL in3';

          document.querySelector('body').setAttribute('style', 'background:#F8FBFC')


          if (trxStatus == 'PAY_LATER_ABORTED') {
            this.payLaterSelected = false;
            this.frequencySelection = true;
          } else if (trxStatus == 'PAY_LATER_REJECTED') {
            this.payLaterSelected = false;
            this.disableFrequencySelection = true;
            this.frequencySelection = true
          } else if(trxStatus == 'NEW') {
            this.frequencySelection = false;
          }
        }
        
      },
      selectIban: function(index) {
        this.selectedIban = this.bankAccounts[index].iban;
      },
      getIssuerColor: function(issuerName) {
        switch (issuerName) {
          case 'ABC Bank':
            return '#6944FF';
          case 'ING Bank':
            return '#FF4600';
          case 'Rabobank':
            return '#EE9A29';
          case 'Bunq':
            return '#000000'
        }
      },
      getIssuerLogo: function(issuerName) {
        switch (issuerName) {
          case 'ABC Bank':
            return logoIssuerABCBank;
          case 'ING Bank':
            return logoIssuerING;
          case 'Rabobank':
            return logoIssuerRabobank;
          case 'Bunq':
            return logoIssuerBunq;
        }
      },
      getIssuerLogoQuickHack: function(iban) {

        if (iban.includes('ABCB')) {
          return logoIssuerABCBank;
        } else if (iban.includes('INGB')) {
          return logoIssuerING;
        } else if (iban.includes('RABO')) {
          return logoIssuerRabobank;
        } else if (iban.includes('BUNQ')) {
          return logoIssuerBunq;
        } 
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .main {
    position: absolute;
    /* top: 0px;
    left: 0px; */
    width: 100%;
  }

  p span {
    display: block;
    /* text-align: right; */
  }
  .content-wrapper {
    padding: 15px;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    max-width: 52em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .container-wrapper {
    padding: 15px;
    width: 100%;
    max-width: 60em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }
  .top-bar {
    /* justify-content: center;
    align-items: center; */
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 80px;
    display: flex;
    flex-direction: row;
    background: #C9DDE4;
    box-shadow: 0px 2px 6px rgba(109, 164, 182, 0.15);
  }
  .top-bar .ideal-logo {
    width: 50px;
  }
  .top-bar .content-wrapper .payment-details {
    text-align: right;
    /* margin-right: 90px; */
    height: 80px;
    padding-top: 16px;
    font-family: 'Montserrat';
  }
  .top-bar .content-wrapper .payment-details .amount {
    font-size: 28px;
  }
  .top-bar .content-wrapper .payment-details .merchant-name {
    font-size: 14px;
    margin-top: -6px;
  }
  .menu-bar {
    height: 50px;
    position: relative;
    padding-top: 70px;

  }
  .menu-bar .cancel-button {
    font-family: 'Montserrat';
    color: #CC0066;
    font-weight: 700;
    /* margin-top: -5px; */
  }
  .content {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* padding: 40px 16px; */
    margin-bottom: -50px;
    gap: 40px;
    /* background: #f8f8fc; */
    position: relative;
  }

  .transaction-summary {
    background-color: #EEF5F7;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 343px;
    margin-top: 30px;
    left: -15px;
    margin-bottom: -50px;
    gap: 24px;
    position: relative;
    padding: 16px;
  }

  .transaction-summary .heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 343px;
    text-align: left;
    margin-bottom: 0px;
    padding-left: 16px;
  }

  .transaction-summary .payment-frequency {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
    gap: 24px;
    width: 311px;
    /* Neutrals / White */
    background: #FFFFFF;
    /* Selector/Light theme / Default */
    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .checkout-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* padding: 40px 16px; */
    margin-bottom: -50px;
    gap: 40px;
    /* background: #f8f8fc; */
    position: relative;
  }

  .checkout-title .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 343px;
    max-width: 60em;
    text-align: left;
    margin-top: 50px;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  .transaction-summary .payment-frequency .title {
    width: 279px;
    height: 24px;

    /* Subtitle 1/Small */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    /* Grey/Darkest */

    color: #283034;
  }

  .transaction-summary .payment-frequency .iban-select {
    width: 275px;
    margin-left: -10px;
    margin-top: 10px;
  }

  #ibanSelect {
   border: none;
  }

  /* .transaction-summary .payment-frequency .iban-select .icon-issuer {
    width: 52px;    
    position: relative;
    margin-top: -10px;
    margin-bottom: -10px;
    padding-right: 10px;
    margin-left: -10px;
  } */


 

  
  /* Dropdown list of bank selector */
  .dropdown-select-box {
    min-width: 275px;
    border-color:#6DA4B6;
    background: #ffffff;
    border: 1px solid #6DA4B6;
    border-radius: 12px;
    min-height: 57px;
    box-shadow: 0px 0px 0px;
    margin-left: -10px;
  }

  .selected-item {
    height: 50px;
  }
  .selected-item .icon-issuer-selected {
    width: 60px;    
    position: relative;
    left: -104px;
    top: -1px;
    padding-right: 10px;
  }
  
  .selected-item .text {
    position: relative;
    top: -40px;
    left: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }
  .selected-item .arrow {
    border: solid #283034;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    position: relative;
    top: -65px;
    left: 115px;
  }

  .selected-item .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .dropdown {
    width: 283px;
  }
  .dropdown-item {
    height: 50px;
  }
  .dropdown-item .icon-issuer-dropdown {
    width: 52px;    
    position: relative;

    padding-right: 10px;
  }
  
  .dropdown-item .text {
    position: relative;
    top: -40px;
    left: 50px;
  }

  /* .transaction-summary .payment-frequency .iban-select .icon-issuer-dropdown {
    width: 52px;    
    position: relative;
    margin-top: -10px;
    margin-bottom: -10px;
    padding-right: 10px;
    margin-left: -10px;
  } */
  .transaction-summary .payment-frequency .selected-account-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-left: -10px;
  }

  .transaction-summary .payment-frequency .frequency-selection {
    margin-left: -20px;
    margin-top: -20px;
  }


  .transaction-summary .order-summary {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 16px;
    gap: 24px;

    width: 311px;
    height: 172px;

    /* Neutrals / White */

    background: #FFFFFF;
    /* Selector/Light theme / Default */

    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  .transaction-summary .order-summary .order-summary-title {
    width: 279px;
    height: 24px;

    /* Subtitle 1/Small */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    /* Grey/Darkest */

    color: #283034;
  }

  .transaction-summary .order-summary .order-details {

    color: #283034;
    text-decoration: none;
    /* display: inline-block; */
    cursor: pointer;
    width: 100%;
    font-size: 0.9em;
    align-items: left;
    justify-content: left;
    text-align: left;
    padding: 0px;
    box-sizing: border-box;
    margin-bottom: -20px;
    
  }

  .transaction-summary .order-summary .order-details .order-details-table {
    width: 100%;
  }

  .transaction-summary .order-summary .order-details .order-details-table .amounts {
    text-align: right;
  }
  .transaction-summary .order-summary .order-details .order-details-table .items {
    font-weight: 600;
  }

  .transaction-summary .order-summary .order-details .order-details-table .total {
    font-weight: 600;
    font-size: 20px;
  }

  .transaction-summary .button-payment {
    background-color: #cc0066;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 100px;
    width: 100%;
    font-size: 1em;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-weight: 700;

  }

  .transaction-summary .sharing-description {
    /* width: 320px; */
    text-align: justify;
    padding: 10px;
  }


  
  .content .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 343px;
    max-width: 60em;
    text-align: left;
    margin-top: 50px;
    margin-bottom: -50px;
    
    
  }

  .content .heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    width: 343px;
    text-align: left;
    margin-bottom: -20px;
  }

  .content .box-details {
    background-color: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;
    border: none;
    color: #283034;
    text-decoration: none;
    /* display: inline-block; */
    cursor: pointer;
    width: 343px;
    font-size: 0.9em;
    align-items: left;
    justify-content: left;
    text-align: left;
    box-sizing: border-box;
    margin-bottom: -20px;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
    position: relative;
  }

  .content .box-details .icon-eye {
    position: absolute;
    left: 280px;
    width: 20px;
  }

  .content .box-details .icon-pencil {
    position: absolute;
    left: 310px;
    width: 20px;
  }

  .content .payment-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 32px;

    width: 311px;
    /* Light theme/Light */
    background: #EEF5F7;
    border-radius: 12px;
  }
  .blur {
    filter: blur(3px);
    -webkit-filter: blur(3px);
  }

  .content .scheduled-date {
    width: 343px;
    margin-top: 40px;

  }

  .content .sharing-description {
    width: 343px;
    /* text-align: justify; */
  }

  .box-information {
    
    padding: 16px;
    margin-top: -15px;
    width: 343px;
    /* Light theme/Medium */
    background: #DEEAEE;
    border-radius: 12px;
    text-align: left;
    box-sizing: border-box;
    position: relative;
  }

  .box-information .description .switch {
    position: absolute;
    right: 20px;
    top: 0px;
  }

  

  .content .payment-details .title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    width: 301px;
    text-align: left;
    margin-bottom: -10px;
  }


  .content .order-details {
    background-color: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;
    border: none;
    color: #283034;
    text-decoration: none;
    /* display: inline-block; */
    cursor: pointer;
    width: 343px;
    font-size: 0.9em;
    align-items: left;
    justify-content: left;
    text-align: left;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: -20px;
    
  }

  .content .order-details .order-details-table {
    width: 100%;
  }

  .content .order-details .order-details-table .amounts {
    text-align: right;
  }
  .content .order-details .order-details-table .items {
    font-weight: 600;
  }

  .content .order-details .order-details-table .total {
    font-weight: 600;
    font-size: 20px;
  }
  .content .selected-iban {
    /* background-color: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;

    border: none;
    color: #283034;
    text-align: center;
    text-decoration: none;

    margin: 4px 2px;
    cursor: pointer;
    width: 311px;
    font-size: 1em;
    height: 40px;
    align-items: center;
    justify-content: center; */

    /* box-sizing: border-box; */

    /* Auto layout */

    /* display: flex;
    flex-direction: row; */
    align-items: center;
    padding: 0px;
    isolation: isolate;

    width: 343px;
    height: 72px;

    background: #6944FF;
    /* Acct selector shadow */

    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.12);
    border-radius: 12px;

    /* Inside auto layout */

    /* flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    z-index: 1; */
  }

  .content .carousel {
    margin-top: -20px;
    margin-bottom: -10px;
  }

  .content .carousel .carousel-iban {
    /* padding: 16px; */
    /* background: #6944FF; */
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    text-align:left;
    height: 72px;
    width: 343px;
    position: relative;
    top: 18px;
    left: 22px;

  }
  .content .carousel .carousel-iban .icon-issuer {
    width: 64px;    
    position: relative;
    padding-top: 4px;
    padding-left: 4px;
  }

  .content .carousel .carousel-iban .issuerName {
    font-size: 14px;
    font-family: 'Montserrat';
    color: #ffffff;
    padding-bottom: 5px;
    position: relative;
    top: -50px;
    left: 80px;
  }
  .content .carousel .carousel-iban .iban {
    /* height: 36px; */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    /* line-height: 36px; */
    color: #FFFFFF;
    position: relative;
    left: 80px;
    top: -55px;
    /* height: 120px; */
  }
  /* .content .selected-iban .icon-issuer {
    position: relative;
    padding-top: 4px;
    left: 0px;
    width: 64px;
  }

  .content .selected-iban .issuer-details {
    position: relative;
    top: -65px;
    left: 80px;
    width: 200px;
    text-align: left;
  }
  .content .selected-iban .issuer-name {

    font-size: 14px;
    font-family: 'Montserrat';
    color: #ffffff;
    padding-bottom: 5px;
  }
  .content .selected-iban .iban {

    font-size: 16px;
    font-family: 'Montserrat';
    color: #ffffff;
    font-weight: 900;
  
  } */


  .content .description {
    width: 343px;
    text-align: left;
  }
  .button-payment {
    background-color: #cc0066;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 100px;
    width: 343px;
    font-size: 1em;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
  }

  .modal-in-progress {
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 343px;
    background-color: #FFFFFF;
    
  }

  .modal-in-progress .modal-description {
    padding-top: 20px;
  }
  
  hr.solid {
    border-top: 1px solid #eaf1f4;
  }

  



  @media screen and (min-width: 320px) {
    .button-preferred-bank {
      font-size: 0.9em;
      }

    .content {
      padding: 40px 0px;
      width: 100%;

    }
  .top-bar .payment-details {
    text-align: right;
    margin-right: 0px;
    margin-top: -80px;
    font-family: 'Montserrat';
  }
  .top-bar .payment-details .amount {
    font-size: 28px;
  }
  .top-bar .payment-details .merchant-name {
    font-size: 14px;
    margin-top: -25px;
  }
  }

  @media screen and (min-width: 640px) {
    .content-wrapper {
      /* display: flex; */
      padding: 15px;
      width: 100%;
      max-width: 52em;
    }

    .content {
      left: 50px;
    }

    .content .title {
      width: 806px;
      max-width: 60em;
    }

    .transaction-summary {
      left: -60px;
      top: 20px;
    }

    .checkout-title .title {
      margin-top: 50px;
      margin-left: -460px;
      margin-bottom: 0px;
    }

    .content .carousel .carousel-iban {
        top: 60px;
        left: 20px;

      }



  /* @media screen and (min-width: 480px) {
    .button-preferred-bank {
      font-size: 1em;
    }
  } */
}
  

</style>

<style>

  .dp__input {
      display:flex;
      align-items:center;
      text-align:left;
      justify-content:center;
      height:40px;
      padding:5px;
      padding-left: 40px;
      width: 343px;
      box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
      border-radius: 12px;
      box-sizing:border-box;
      position:relative;
      border: none;
  }

  .dp__select {
    color:#CC0066;
  }

  .dp__theme_light {
    --dp-primary-color: #CC0066;
  }
</style>
