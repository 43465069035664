<template>
  <div class="container-register">
    <v-list lines="one">
      <v-list-item class="list-item">Profile</v-list-item>
      <v-list-item class="list-item">Linked accounts</v-list-item>
      <v-list-item class="list-item">Address book</v-list-item>
      <v-list-item class="list-item">Trused browser</v-list-item>
      <v-list-item class="list-item">Trusted webshop</v-list-item>
     <a href="/#/profile/devices"><v-list-item class="list-item">Registered devices</v-list-item></a>
    </v-list>
    
  </div>
</template>

<script>
// import axios from "axios";
//   const SimpleWebAuthnBrowser = require('@simplewebauthn/browser');
const AWS = require('aws-sdk');
const { EvidentlyClient, EvaluateFeatureCommand } = require('@aws-sdk/client-evidently');
// const { CognitoIdentityClient, GetIdCommand, GetCredentialsForIdentityCommand } = require("@aws-sdk/client-cognito-identity");
// const { fromCognitoIdentityPool } = require("@aws-sdk/credential-provider-cognito-identity");

// import { STSClient, AssumeRoleCommand } from "@aws-sdk/client-sts";
// import { fromCognitoIdentityPool, fromTemporaryCredentials } from "@aws-sdk/credential-providers";



export default {
  name: "ProfileComponent",
  data() {
    return {
      profileName: '',
    }
  },
  created() {
    // this.getProfile();
    this.checkFeature();
  },
  methods: {
      // getProfile: async function() {
      //     const resp = await fetch('http://localhost:8080/ideal-profile/', {credentials: 'include'});


      //     if (resp.status == 401) {
      //          window.location.href = '/login';
      //     }
      //     else {
      //         let profileResponse = await resp.json();
      //         this.profileName = profileResponse.name;
      //     }
          
      // }

    checkFeature: async function() {
      // console.log('im at profile')
      // Assuming AWS config is done and Cognito Identity is set up
      // const evidently = new AWS.Evidently({
      //   apiVersion: '2021-02-01'
      // });



      const identityPoolId = 'eu-central-1:46eca698-ccd2-4793-afc1-5ec447458572';
      const region = 'eu-central-1';
      const guestRoleArn = "arn:aws:iam::471392165117:role/cognito-unauth-poc-pages_role"
      // const roleArn = 'arn:aws:iam::471392165117:role/cognito-unauth-poc-pages_role'

      // // Create a Cognito Identity Client
      // const cognitoClient = new CognitoIdentityClient({ region });

      // // Create Evidently client using Cognito Identity Pool credentials
      // const evidentlyClient = new Evidently({
      //   region,
      //   credentials: fromCognitoIdentityPool({
      //     client: cognitoClient,
      //     identityPoolId: identityPoolId,
      //     roleArn: guestRoleArn
      //   })
      // });
      

// Function to get Cognito Identity credentials
// const getCognitoCredentials = async () => {
//   const identityClient = new CognitoIdentityClient({ region });

//   // Get the identity ID
//   const getIdCommand = new GetIdCommand({ IdentityPoolId: identityPoolId });
//   const identityIdResponse = await identityClient.send(getIdCommand);
//   console.log("Identity ID Response:", identityIdResponse);
//   const identityId = identityIdResponse.IdentityId;

//   // Get the credentials for the identity
//   const getCredentialsCommand = new GetCredentialsForIdentityCommand({ IdentityId: identityId });
//   const credentialsResponse = await identityClient.send(getCredentialsCommand);
//   console.log("Cognito Credentials Response:", credentialsResponse);
//   const cognitoCredentials = credentialsResponse.Credentials;

//   return {
//       accessKeyId: cognitoCredentials.AccessKeyId,
//       secretAccessKey: cognitoCredentials.SecretKey,
//       sessionToken: cognitoCredentials.SessionToken,
//       expiration: cognitoCredentials.Expiration
//   };
// };

// Function to create Evidently client with Cognito credentials
// const createEvidentlyClient = async () => {
//     const credentials = await getCognitoCredentials();
//     console.log("Creating Evidently client with credentials:", credentials);

//     return new EvidentlyClient({
//         region,
//         endpoint: 'https://evidently.eu-central-1.amazonaws.com',
//         credentials: {
//             accessKeyId: credentials.accessKeyId,
//             secretAccessKey: credentials.secretAccessKey,
//             sessionToken: credentials.sessionToken,
//             expiration: credentials.expiration
//         }
//     });
// };
  // const credentials = await getCognitoCredentials();

  const credentials = new AWS.CognitoIdentityCredentials(
            {
                IdentityPoolId: identityPoolId,
                RoleArn: guestRoleArn,
            },
            { region: region}
        );
      
        await credentials.getPromise();

        console.log('credentials', credentials)

      try {
        const evidentlyClient = new EvidentlyClient({
          region,
          endpoint: 'https://evidently.eu-central-1.amazonaws.com',
          credentials: {
              accessKeyId: credentials.accessKeyId,
              secretAccessKey: credentials.secretAccessKey,
              sessionToken: credentials.sessionToken,
              expiration: credentials.expiration
          }
      });
        
        console.log('evidentlyclient', evidentlyClient);

        const params = {
          project: 'tftest',  // Replace with your project ID
          feature: 'abtest',  // Replace with your feature name
          entityId: 'myid',  // This should be a unique identifier for the user or session
        };
        // const data = await evidentlyClient.evaluateFeature(params);
        const command = new EvaluateFeatureCommand(params);
        const data = await evidentlyClient.send(command);
        console.log(data)
        this.featureEnabled = data.value;


        

      } catch (error) {
        console.error('Error fetching feature:', error);
        this.featureEnabled = false;
      }


    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  width: 343px;
  height: 36px;

  /* H4/Small */

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 150% */

  text-align: left;

  /* Neutrals/Black */

  color: #080808;
  /* margin-bottom: -30px; */
  margin-left: auto;
  margin-right: auto;
}
.container-register {
      /* display: flex;
  flex-direction: column;



  background: #F8FBFC;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0; */
  align-items: center;
  /* text-align: center; */
  /* width: 343px; */
  /* height: 536px; */
  background: #F8FBFC;
  /* padding: 24px 10px 40px; */
  /* padding-top: 20px; */
  /* width: 343px; */
  gap: 40px;
  margin-left: auto;
  margin-right: auto;
  /* width: 100%; */
  display: block;
}


.block {
  margin-bottom: -20px;
  width: 343px;
}
.description {
  width: 343px;
  height: 72px;
  /* Body 1/Small */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-align: justify;
  /* or 150% */
  /* Grey/Darkest */
  color: #283034;
  /* Inside auto layout */
  /* margin-bottom: -40px; */
  margin-left: auto;
  margin-right: auto;
}
.label {
  width: 343px;
  height: 18px;
  text-align: left;

  /* Caption */

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */


  /* Grey/Base */

  color: #525C62;
  margin-left: auto;
  margin-right: auto;
}
.input {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;

  width: 343px;
  height: 40px;

  /* Neutrals / White */

  background: #FFFFFF;
  /* Light theme/Base */

  border: 1px solid #BFD7DF;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-left: auto;
  margin-right: auto;
}
.errorMsg {
  width: 343px;
  height: 18px;
  text-align: left;

  /* Caption */

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  margin-left: auto;
  margin-right: auto;

  /* Grey/Base */

  color: #ED230D;
}
.button-register {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  gap: 10px;

  width: 343px;
  height: 40px;

  /* Primary / Base */
  color: #ffffff;
  background: #CC0066;
  border-radius: 100px;
  border: 0px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.list-item {
  text-align: left;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background: #F8FBFC;
}

a, a:hover, a:visited, a:active {
color: inherit;
text-decoration: none;
}
hr.solid {
  border-top: 1px solid #eaf1f4;
}
@media screen and (min-width: 320px) {
  .button-preferred-bank {
    font-size: 0.9em;
  }
  .content-description {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (min-width: 480px) {
  .button-preferred-bank {
    font-size: 1em;
  }
}


</style>
