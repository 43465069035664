<template>

    <PaymentNormalCheckoutComponent 
      v-if="isCheckoutVisible" 
      @switch-to-component2="switchToBankSelectionComponent"
      :selectedIbanNew="selectedIbanNew"
    />
    <PaymentBankSelectionComponent 
      v-if="!isCheckoutVisible" 
      @switch-to-component1="switchToNormalCheckoutComponent"
      :bankAccounts="bankAccounts"
      :selectedIban="selectedIban"
    />

    <BottombarComponent  />

</template>
<script>
// @ is an alias to /src
import PaymentNormalCheckoutComponent from "@/components/PaymentNormalCheckoutComponent.vue";
import BottombarComponent from "@/components/BottombarComponent.vue";
import PaymentBankSelectionComponent from "@/components/PaymentBankSelectionComponent.vue";

// this.awsRum.recordPageView({ pageId: '/checkout'})


export default {
  name: "paymentCheckoutPage",
  components: {
    PaymentNormalCheckoutComponent,
    PaymentBankSelectionComponent,
    BottombarComponent
  },
  data() {
    return {
      isCheckoutVisible: true,
      bankAccounts: null,
      selectedIban: null,
      selectedIbanNew: null
    }
  },
  methods: {
    switchToBankSelectionComponent(bankAccounts, selectedIban) {
      this.isCheckoutVisible = false;
      this.bankAccounts = bankAccounts;
      this.selectedIban = selectedIban;
    },
    switchToNormalCheckoutComponent(selectedIban) {
      this.isCheckoutVisible = true;
      this.selectedIbanNew = selectedIban;
    },
  },
};
</script>
<style scoped>
  body {
    background-color: #F8FBFC;
    font-family: 'Montserrat';
  }

</style>
