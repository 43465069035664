<template>

    <PaymentComponent />
    <BottombarComponent  />

</template>
<script>
// @ is an alias to /src
import PaymentComponent from "@/components/PaymentComponent.vue";
import BottombarComponent from "@/components/BottombarComponent.vue";

// this.awsRum.recordPageView({ pageId: '/payment'})


export default {
  name: "paymentPage",
  components: {
    PaymentComponent,
    BottombarComponent
  }
};
</script>
<style scoped>
  html body {
    background-color: #F8FBFC;
    font-family: 'Montserrat';
    /* margin: 0%; */

    max-width: 100%;
    overflow-x: hidden;
  }

</style>
