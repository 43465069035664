<template>
    <div class="bottom-bar">
      <div v-if="$isMobile()">
        <table class="bottom-menu">
          <tr>
            <td>Hoe werkt het?</td>
            <td class="right-align bold-text">Engels</td>
          </tr>
          <tr>Over ons</tr>
          <tr>Privacy statement</tr>
          <tr>© 2024 IDEAL</tr>
        </table>
        
      </div>
      <div v-else>
        <table class="bottom-menu">
          <tr>
            <td>Hoe werkt het?</td>
            <td>Over ons</td>
            <td>Privacy statement</td>
            <td>Engels</td>
          </tr>
          <tr>
            <td>© 2024 IDEAL</td>
          </tr>
        </table>
      </div>
    </div>
</template>
<script>
export default {
  name: "bottombarComponent",
  created() {
    this.$isMobile();
  }
};

</script>
<style scoped>
  .bottom-bar {
    /* width: 130px;
    height: 24px; */

    /* Body 2/Large */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    /* display: flex;
    align-items: center; */

    /* Grey/Base */

    color: #525C62;


    /* Inside auto layout */

    /* flex: none;
    order: 0;
    flex-grow: 0; */
  }

  .bottom-menu {
    padding-top: 40px;
    width: 100%;
    /* max-width: 100em; */
    margin-left: auto;
    margin-right: auto;
    text-align: left;
  }

  .right-align {
    text-align: right;
  }

  .bold-text {
    font-weight: 700;
  }

  @media screen and (min-width: 320px) {
    .bottom-menu {
      padding-left: 20px;
      padding-right: 20px;

    }

    .bottom-bar {
      margin-top: 50px;
    }
  }
  /* @media screen and (min-width: 480px) {
    .bottom-menu {
      padding-left: 15px;
      padding-right: 15px;
    }
  } */

</style>