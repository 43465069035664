<template>
  <div></div>
</template>
<script>
export default {
  name: "PaymentConfirmComponent",
  data() {
    return {
      bffUrl: process.env.VUE_APP_BFF_URL,
    }
  },
  async mounted() {

    this.getTransactionStatus();

  },
  methods: {
    getTransactionStatus: async function() {
      const trxId = this.$route.params.transactionId

      const resp = await fetch(this.bffUrl + '/pay-later/' + trxId);

      const returnUrl =  await resp.json()

      window.location.href = returnUrl;

    }
  }
};

</script>
<style scoped>


</style>