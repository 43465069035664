<template>
  <!-- <div class="main"> -->
    <div class="top-bar">
        <div class="container">
          <img class="ideal-logo" src="../assets/ideal-logo.png">
          <div class="payment-details">
            <p class="amount">€ {{ amount }}</p>
            <p class="merchant-name">{{ transaction.creditorName }}</p>
          </div>
        </div>
        
    </div>
    <div class="menu-bar">
      <div class="container">
        <!-- <a :href="merchantUrl + '/#/login'"> -->
          <div class="cancel-button">
            Cancel
          </div>
        <!-- </a> -->
      </div>
    </div>
    <div v-show="showLoader">
      <img href="../assets/loading.gif">
    </div>
    <div v-show="!showLoader">
    <div class="content">
        <div class="title">
          Selected account
        </div>
        <!-- <div class="selected-iban">
          <img class="icon-issuer" src="../assets/icon-123bank.png">
          <div class="iban">NL42 123B **** **00 01</div>
        </div> -->
    </div>
    <div class="account-select">
      <Splide ref="splide" @splide:move="onSplideMove" :options="splideOptions" >
        <SplideSlide v-for="bankAccount in bankAccounts" :key="bankAccount">
          <div class="carousel-iban" v-bind:style="{ background: getIssuerColor(bankAccount.issuer) }"> 
            <img :src="getIssuerLogo(bankAccount.issuer)" class="icon-issuer">
            <div class="iban">{{bankAccount.iban}}</div>
          </div>
        </SplideSlide>
      </Splide>
    </div>
    <div class="content">
      <div class="datepicker"> 
        <Datepicker v-show="scheduling" v-model="date" :format="format" :enableTimePicker="false" :minDate="new Date()" :maxDate="ultimateExecutionDate" v-bind:clearable="false"></Datepicker>
      </div>
      <div v-show="showScheduling || showRecurring" class="box-information">
          <div class="description">This is a <b>{{transactionType}} payment</b></div>
      </div>
      <div>       
        <button v-on:click="pay"  class="button-payment">Sign in and Pay</button>
      </div>
      <div> 
        <button class="button-choose-bank">Choose another bank</button>
      </div>
      
    </div>
    <v-dialog
      v-model="dialog"
      :scrim="true"
      persistent
    >
      <div class="modal-in-progress">

          <v-progress-circular
            indeterminate
            color="blue-lighten-3"
            model-value="20"
            :size="128"
            :width="8"
          ></v-progress-circular>
          <p class="modal-description">Please wait</p>

      </div>
    </v-dialog>
  </div>
  <!-- </div> -->
</template>

<script>
  // import axios from "axios";
  const SimpleWebAuthnBrowser = require('@simplewebauthn/browser');
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css'
  import { ref } from 'vue';
  import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import '@splidejs/vue-splide/css';

  import logoIssuerABCBank from "../assets/logo-issuer-abcbank.png";
  import logoIssuerING from "../assets/logo-issuer-ing.png";
  import logoIssuerRabobank from "../assets/logo-issuer-rabobank.png";
  import logoIssuerBunq from "../assets/logo-issuer-bunq.png"




  // const bankAccountCarousel = ref(nul);


  export default {
    name: "PaymentComponent",
    components: { 
      Datepicker,
      Splide,
      SplideSlide
    },
    data() {
      return {
        title: "Payment",
        scaUrl: process.env.VUE_APP_SCA_URL,
        trxServiceUrl: process.env.VUE_APP_TRX_SERVICE_URL,
        bffUrl: process.env.VUE_APP_BFF_URL,
        trxId: this.$route.params.transactionId,
        amount: 0,
        merchantName: '',
        // date: null,
        transaction: {},
        scheduling: false,
        recurring: false,
        masked: true,
        userName: 'jiri.oen@gmail.com',
        bankAccounts: [],
        loading: true,
        selectedIban: '',
        dialog: false,
        // awsRumClient: this.awsRum
      }

    },
    created() {
      this.getUser(this.masked);
      this.getPaymentDetails();
      // this.awsRumClient.recordPageView({ pageId: '/payment', pageTags: ['en', 'landing']})
    },
    setup() {
        const date = ref(new Date());
        
        // In case of a range picker, you'll receive [Date, Date]
        const format = (date) => {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}-${month}-${year}`;
        }

        const splide = ref();

        const splideOptions = { 
          arrows: true, 
          gap: '1.2rem', 
          padding: '30%',
          fixedHeight: '260px', 
          // fixedWidth: 311, 

          // isNavigation: true,
          breakpoints: {
            640: {
              arrows: false, 
              perPage: 5, 
              focus: 'center', 
              // trimSpace: true, 
              fixedWidth: 311, 
              gap: '1.2rem', 
              padding: '20%',
            },
          }
        }
        
        
        return {
            date,
            format,
            splideOptions,
            splide
            
        }
    },
    computed: {
      showRecurring() {
        return this.recurring;
      },
      showScheduling () {
        return this.scheduling;
      },
      showLoader () {
        return this.loading;
      }
    },

    methods: {
      async getUser(masked) {

        let request = { 
          email: this.userName,
          masked: masked
        }

        const resp = await fetch(this.bffUrl + '/users/identify', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(request),
          });
        const retrievedUser = await resp.json();
        
        this.phoneNumber = retrievedUser.phoneNumber;
        this.bankAccounts = retrievedUser.bankAccounts;
        this.loading = false;
        this.selectedIban = this.bankAccounts[0].iban;

      },
      // eslint-disable-next-line no-unused-vars
      onSplideMove: function(newIndex, currentIndex, prevIndex) {
        // console.log('moving to next index', newIndex)
        // console.log('current index is', currentIndex)
        this.selectedIban = this.bankAccounts[currentIndex].iban
        // console.log(prevIndex)
      },
      authenticate: async function() {

          const { startAuthentication } = SimpleWebAuthnBrowser;
          const resp = await fetch(this.scaUrl + '/generate-signing-options/' + this.trxId);

          let asseResp;
          try {
            const opts = await resp.json();
            console.log(opts.challenge);
            
            asseResp = await startAuthentication(opts);

          } catch (error) {
            // elemError.innerText = error;
            throw new Error(error);
          }

          

          console.log(asseResp);
          
          const authResponse = await fetch(this.scaUrl + '/verify-signing/' + this.trxId, {
            method: 'POST',
            credentials: 'include',
            // mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(asseResp),
          });

          const authResponseJSON = await authResponse.json();
          console.log(authResponseJSON);
          

          if (authResponseJSON && authResponseJSON.verified) {
            this.$loggedIn = true;
            window.location.href = authResponseJSON.returnUrl + "?idealTransactionId=" + this.trxId;
            
            
          } else {
            alert("Authentication Failed!");
          }
      },
      authorisePayment: async function(signItemId) {

          const { startAuthentication } = SimpleWebAuthnBrowser;
          const resp = await fetch(this.scaUrl + '/sign/generate-signing-options/' + signItemId);
          console.log(resp);
          let asseResp;
          try {
            const opts = await resp.json();
            console.log(opts);
            console.log(opts.challenge);
            
            asseResp = await startAuthentication(opts, false);

          } catch (error) {
            // elemError.innerText = error;
            console.log(error)
            throw new Error(error);
          }

          

          console.log(asseResp);

          this.dialog = true;
          
          const authResponse = await fetch(this.scaUrl + '/sign/verify-signing/' + signItemId, {
            method: 'POST',
            credentials: 'include',
            // mode: 'no-cors',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(asseResp),
          });

          const authResponseJSON = await authResponse.json();
          console.log(authResponseJSON);
          

          if (authResponseJSON && authResponseJSON.verified) {
            // console.log('Authenticated!');
            return true;
            
          } else {
            alert("Authentication Failed!");
          }
      },
      pay: async function() {
        // this.awsRumClient.recordEvent('ButtonClicked_PAY', {
        //         action: "INITIATE_PAYMENT",
        //         transactionType: this.transaction.transactionType,
        //         user_interaction: {
        //             interaction : "click",
        //         }            }
        // )

        let selectedExecutionDate = ''
        if (this.scheduling) {
          selectedExecutionDate = this.date.getFullYear() + '-' + (this.date.getMonth()+1) + '-' + this.date.getDate();
        }

        const request = {
          executionDate: selectedExecutionDate,
          debtorIban: this.selectedIban,
          userName: this.userName
        }

        console.log(request);

        const signTrxResp = await fetch(this.bffUrl + '/pay/' + this.trxId , {

              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              
              body: JSON.stringify(request),

            });

        const signItemTrxResp = await signTrxResp.json();   
        const signed = await this.authorisePayment(signItemTrxResp.signItemId);
        
        console.log(signed);
        if (signed) {
          const resp = await fetch(this.bffUrl + '/transactions/' + this.trxId);

          const transactionResp = await resp.json();
          this.transaction = transactionResp;

          this.dialog = false;

          if (this.transaction.status == 'PAYMENT_AUTHORISED' || this.transaction.status == 'PAYMENT_EXECUTED') {
            this.$loggedIn = true;
            const returnUrl = this.transaction.returnUrl + "?idealTransactionId=" + this.trxId
            console.log(returnUrl)

            if (window.opener) {
              // inside popup
              window.close();
            } else {
              window.location.href = returnUrl;

            }
          }
           
            
        }
      },
      getPaymentDetails: async function() {


        // const resp = await fetch(this.trxServiceUrl + '/transactions/' + this.trxId);
        const resp = await fetch(this.bffUrl + '/transactions/' + this.trxId);

        this.transaction = await resp.json();
        this.amount = this.transaction.amount.amount;
        if (this.transaction.transactionFlow == 'FAST_CHECKOUT') { // standard checkout
          this.checkout = true; 
        }

        if (this.transaction.transactionType == 'RECURRING') { // checkout with recurring
          this.recurring = true;
          this.transactionType = 'recurring'

          if (this.transaction.recurringPeriod == 'WEEKLY') {
          this.recurringPeriodLabel = '/we'
          }
          else if (this.transaction.recurringPeriod == 'MONTHLY') {
            this.recurringPeriodLabel = '/mo'
          } else if (this.transaction.recurringPeriod == 'YEARLY') {
            this.recurringPeriodLabel = '/yr'
          }
        } else if (this.transaction.transactionType == 'SCHEDULING') { // checkout with scheduling 
          this.transactionType = 'scheduled';

          this.ultimateExecutionDate = this.transaction.ultimateExecutionDate;

          if (this.ultimateExecutionDate != undefined) {
            this.scheduling = true;
            this.date = new Date();
            this.date.maxDate = new Date(this.ultimateExecutionDate);
          }
        }
      },
      getIssuerColor: function(issuerName) {
        switch (issuerName) {
          case 'ABC Bank':
            return '#6944FF';
          case 'ING Bank':
            return '#FF4600';
          case 'Rabobank':
            return '#EE9A29';
          case 'Bunq':
            return '#000000'
        }
      },
      getIssuerLogo: function(issuerName) {
        switch (issuerName) {
          case 'ABC Bank':
            return logoIssuerABCBank;
          case 'ING Bank':
            return logoIssuerING;
          case 'Rabobank':
            return logoIssuerRabobank;
          case 'Bunq':
            return logoIssuerBunq;
        }
      }
    }
  }
</script>



<style scoped>

  .container {
    padding-top: 15px;
    width: 350px;
    /* max-width: 52em; */
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    align-items: center;
  }


  .top-bar {
    /* justify-content: center;
    align-items: center; */
    position: absolute;
    width: 100%;
    top: 0px;
    left: 0px;
    height: 80px;
    display: flex;
    flex-direction: row;
    background: #C9DDE4;
    box-shadow: 0px 2px 6px rgba(109, 164, 182, 0.15);
  }
  .top-bar .ideal-logo {
    width: 50px;
  }
  .top-bar .container .payment-details {
    text-align: right;
    /* margin-right: 90px; */
    height: 80px;
    padding-top: 16px;
    font-family: 'Montserrat';
  }
  .top-bar .container .payment-details .amount {
    font-size: 28px;
  }
  .top-bar .container .payment-details .merchant-name {
    font-size: 14px;
    margin-top: -6px;
  }

  .menu-bar {
    height: 50px;
    position: relative;
    padding-top: 70px;

  }
  .menu-bar .cancel-button {
    font-family: 'Montserrat';
    color: #CC0066;
    font-weight: 700;
    /* margin-top: -5px; */
  }
  .content {
    
    /* display: flex;
    flex-direction: column; */
    align-items: center;
    width: 100%;
    /* padding: 40px 16px; */
    gap: 40px;
    /* background: #f8f8fc; */
    /* position: relative; */
    
    
  }

  .content .title {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 24px;
    margin-top: 20px;
  }

  .content .datepicker {
    display: flex;
    justify-content: center;
    margin-top: 20px;
 
  }

  .content .box-information {
    
    padding: 16px;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 343px;
    /* Light theme/Medium */
    background: #DEEAEE;
    border-radius: 12px;
    /* text-align: left; */
    /* box-sizing: border-box; */
    display: flex;
    justify-content: center;
  }

  .content .selected-iban {
    /* background-color: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
    border-radius: 12px;

    border: none;
    color: #283034;
    text-align: center;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
    width: 343px;
    font-size: 1em;
    height: 40px;
    align-items: center;
    justify-content: center; */
    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    gap: 40px;

    /* position: absolute; */
    width: 311px;
    height: 194px;
    /* left: 0px;
    top: 0px; */

    background: #6944FF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
  }
  .content .selected-iban .icon-issuer {
    position: relative;
    padding-top: 4px;
    left: 0px;
    width: 64px;
  }
  .content .selected-iban .iban {
    /* margin-top: -29px;
    margin-left: 0px;
    font-size: 16px;
    font-family: 'Montserrat';
    color: #FFFFFF */
    /* Auto layout */

/* Label */


  /* width: 143px; */
    height: 36px;

    /* H4/Small */

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    /* identical to box height, or 150% */


    /* Neutrals / White */

    color: #FFFFFF;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
  }

  .account-select {
    /* align-items: center; */
    /* position: relative; */
    /* left: 120px; */
    /* width: 450px; */
    width: 100%;
    margin-bottom: -50px;
  }

  /* .account-select {
    align-content: center;
    margin: -20px;
    position: relative;
    left: -20px;
    

  } */


  .account-select .carousel-iban {
    padding: 16px;
    background: #6944FF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    text-align:left;
    height: 194px;

  }
  .account-select .carousel-iban .icon-issuer {

    height: 40px;
  }
  .account-select .carousel-iban .iban {
    height: 36px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    align-items: flex-end;
    height: 120px;
  }


  .content .description {
    width: 343px;
    text-align: left;
  }


  .button-payment {
    background-color: #cc0066;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    margin-top: 30px;
    margin-bottom: 20px;
    cursor: pointer;
    border-radius: 100px;
    width: 343px;
    font-size: 1em;
    height: 40px;
    align-items: center;
    justify-content: center;
    font-weight: 700;

  }
  .button-choose-bank {
    background-color: #f8f8fc;

    border: none;
    text-align: center;
    text-decoration: none;
    /* display: inline-block; */
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 100px;
    border: 1px solid #080808;
    width: 343px;
    font-size: 1em;
    height: 40px;
    align-items: center;
    justify-content: center;
    margin-top: -30px;
    color: #080808;
    font-weight: 700;

  }

  .modal-in-progress {
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    text-align: center;
    padding: 30px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    width: 343px;
    background-color: #FFFFFF;
    
  }

  .modal-in-progress .modal-description {
    padding-top: 20px;
  }
  hr.solid {
    border-top: 1px solid #eaf1f4;
  }



  



  @media screen and (min-width: 320px) {
    .button-preferred-bank {
      font-size: 0.9em;
      }

    .content {
      padding: 40px 0px;
      width: 100%;

    }
  .top-bar .payment-details {
    text-align: right;
    margin-right: 0px;
    margin-top: -80px;
    font-family: 'Montserrat';
  }
  .top-bar .payment-details .amount {
    font-size: 28px;
  }
  .top-bar .payment-details .merchant-name {
    font-size: 14px;
    margin-top: -25px;
  }
  }



  @media screen and (min-width: 768px) {
    .account-select {
      /* align-items: center; */
      /* position: relative; */
      /* left: 120px; */
      /* width: 450px; */
        /* width: 100%; */
      /* max-width: 52em; */
      /* max-width: 60em;     */
      display: flex;
      /* max-width: 50%; */
      justify-content: center;
      
    }

    .container {
      padding: 15px;
      width: 100%;
      /* max-width: 52em; */
      max-width: 55em;
  }
  }
  

</style>

<style>

  .dp__input {
      display:flex;
      align-items:center;
      text-align:left;
      justify-content:center;
      height:40px;
      padding:5px;
      padding-left: 40px;
      width: 343px;
      box-shadow: 0px 2px 9px rgba(109, 164, 182, 0.2);
      border-radius: 12px;
      box-sizing:border-box;
      position:relative
  }

  .dp__select {
    color:#CC0066;
  }

  .dp__theme_light {
    --dp-primary-color: #CC0066;
  }

  /* .carousel__item {
    height: 194px;
    width: 311px;
    background-color: #6944FF;
    color: #ffffff;
    font-size: 20px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */


  


</style>
